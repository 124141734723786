// DocumentCard.js
import React from 'react';
import styles from './DocumentCard.module.css'; // Create a separate CSS file for this component if needed

const SUMMARY_LIMIT = 100;

const DocumentCard = ({ result, onClick, filters }) => {
    const summary = result._source.meta.summary;
    const truncatedSummary =
        (summary && summary.length > SUMMARY_LIMIT)
            ? summary.slice(0, SUMMARY_LIMIT) + "..."
            : summary;

    return (
        <div className={styles["result-card"]}>
            <div className={styles["source"]}><span className={styles['document-score']}>{result._score}</span> | {result._source.indexed_at} | {result._source.source} | {result._source?.extras?.fonte}
            </div>
            <div
                onClick={() => onClick(result)}
                className={styles["title"]}
            >
                {result._source.title}
            </div>
            {/*<p className={styles['document-meta']}>*/}
            {/*    <span className={styles['document-score']}>{result._score}</span> | {result._source.meta.relevance} |*/}
            {/*    <span className={styles[`sentiment-${result._source.meta.sentiment}`]}>*/}
            {/*        {result._source.meta.sentiment}</span>*/}
            {/*</p>*/}
            {filters?.entities && (
                <p className={styles['document-meta']}>
                    <b>Entes: </b>{result._source.meta.entities.join('|')}
                </p>
            )}
            {filters?.subjects && (
                <p className={styles['document-meta']}>
                    <b>Assuntos: </b>{result._source.meta.subjects.join('|')}
                </p>
            )}
            {filters?.main_topics && (
                <p className={styles['document-meta']}>
                    <b>Temas principais: </b>{result._source.meta.main_topics.join('|')}
                </p>
            )}
            {filters?.authors && (
                <p className={styles['document-meta']}>
                    <b>Autores: </b>{result._source.meta?.authors.join('|')}
                </p>
            )}
            {filters?.author_handle && (
                <p className={styles['document-meta']}>
                    <b>Author handle: </b>{result._source.author_handle}
                </p>
            )}
            <p>
                <span className={styles['document-date']}>
                    {result._source.document_date}
                </span> - {truncatedSummary}
            </p>
            {!!result._source.url && (
                <div className={styles["url"]}>
                    <a href={result._source.url} target="_blank" rel="noopener noreferrer">
                        {result._source.url}
                    </a>
                </div>
            )}
        </div>
    );
};

export default DocumentCard;
