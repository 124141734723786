import axios from 'axios';
import queryHistory from "./queryHistory";

const API_BASE_URL_CONFIGURATION = '${APP_CONFIG_API_BASE_URL}'
export const API_BASE_URL = (API_BASE_URL_CONFIGURATION && API_BASE_URL_CONFIGURATION.includes('http'))
  ? API_BASE_URL_CONFIGURATION 
  : 'http://localhost:8081';

const getHeaders = (token) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
});

// Login function using axios
export const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/v2/token`, {
      email: username,
      password: password,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = response.data;
    localStorage.setItem('jwt_token', data.access_token);
    return data.access_token;
  } catch (error) {
    console.error('Login failed:', error);
    throw new Error('Login failed');
  }
};



export const createAndActivateRadar = async (token, radarName,
                                             query,
                                             keywords,
                                             combine_mode,
                                             isExactKeyword,
                                             url,
                                             title,
                                             filters = {}, sortBy,
                                             aggregateBy,
                                             from = 0, size = 5) => {
  const response = await axios.post(`${API_BASE_URL}/api/v2/alerts`,
      {
        radar_name: radarName,
        semantic_query: query,
        keyword_exact_query: isExactKeyword? keywords: '',
        keyword_fuzzy_query: isExactKeyword? '': keywords,
        url: url,
        title: title,
        combine_mode,
        keyword_exact_match: isExactKeyword,
        filters: JSON.stringify(filters)
      },
      {
        headers: getHeaders(token)
      }
  );

  return response.data;
};




// Search documents function using axios
export const searchDocuments = async (token,
                                      query,
                                      keywords,
                                      combine_mode,
                                      isExactKeyword,
                                      url,
                                      title,
                                      filters = {}, sortBy,
                                      aggregateBy,
                                      from = 0, size = 5) => {
  console.log( 'filters: ', filters);


  const response = await axios.get(`${API_BASE_URL}/api/v2/documents/search`, {
    headers: getHeaders(token),
    params: {
      semantic_query: query,
      keyword_exact_query: isExactKeyword? keywords: '',
      keyword_fuzzy_query: isExactKeyword? '': keywords,
      url: url,
      title: title,
      combine_mode,
      filters: JSON.stringify(filters),
      sort_by: sortBy,
      aggregate_by: aggregateBy,
      from: from,
      size: size,
    },
  });

  return response.data;
};

export const searchDocuments4 = async (token,
                                      query,
                                      keywords,
                                      combine_mode,
                                      isExactKeyword,
                                      url,
                                      title,
                                      filters = {}, sortBy,
                                      aggregateBy,
                                      from = 0, size = 5,
                                      nohistory=false) => {

  const searchParams = {
    semantic_query: query,
    keyword_exact_query: isExactKeyword? keywords: '',
    keyword_fuzzy_query: isExactKeyword? '': keywords,
    url: url,
    title: title,
    combine_mode,
    filters: filters,
    sort_by: sortBy,
    aggregate_by: aggregateBy,
  }

  console.log('searchParams', searchParams);

  const response = await axios.post(`${API_BASE_URL}/api/v4/documents/search`,
      searchParams,
      {
        headers: getHeaders(token),
        params: {
          from: from,
          size: size,
        }
      });

  if (!nohistory)
    queryHistory.add(searchParams);
  return response.data;
};



export const saveSearch = async (token, query,
                                 keywords,
                                 combine_mode,
                                 isExactKeyword,
                                 url,
                                 title,
                                 filters = {}, sortBy,
                                      aggregateBy) => {
  console.log( 'filters: ', filters);


  const response = await axios.get(`${API_BASE_URL}/api/v2/documents/search/save`, {
    headers: getHeaders(token),
    params: {
      name: null,
      semantic_query: query,
      keyword_exact_query: isExactKeyword? keywords: '',
      keyword_fuzzy_query: isExactKeyword? '': keywords,
      url: url,
      title: title,
      combine_mode,
      filters: JSON.stringify(filters),
      sort_by: sortBy,
      aggregate_by: aggregateBy
    },
  });

  return response.data;
};




export const getIndexes = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/indexes`, {
    headers: getHeaders(token)
  });

  return response.data;
};


export const getSavedSearchList = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/saved_search`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const deleteSavedSearch = async (token, id) => {
  await axios.delete(`${API_BASE_URL}/api/v2/saved_search/${id}`, {
    headers: getHeaders(token)
  });
};



export const getAlerts = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/alerts`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const deleteAlert = async (token, id) => {
  await axios.delete(`${API_BASE_URL}/api/v2/alerts/${id}`, {
    headers: getHeaders(token)
  });
};

export const resetAlert = async (token, id) => {
  await axios.put(`${API_BASE_URL}/api/v2/alerts/${id}/reset`, null, {
    headers: getHeaders(token)
  });
};

export const getAlertUnseenDocuments = async (token, id) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/alerts/${id}/unseen`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const getAlertDetail = async (token, id) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/alerts/${id}`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const runPlainQuery = async (token, query) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/query/plain`, {
    headers: getHeaders(token),
    params: {
      query
    },
  });
  return response.data;
};

export const toggleSaveSearchAlert = async (token, id) => {
  await axios.put(`${API_BASE_URL}/api/v2/saved_search/${id}/toggle-alert`, null, {
    headers: getHeaders(token)
  });
}

export const getLogCounts = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/log-count`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const getLogCountsBySource = async (token, service) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/log-count-by-source`, {
    headers: getHeaders(token),
    params: {
      service
    },
  });

  return response.data;
};


export const getQueueCount = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/queue-count`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const getErrorLog = async (token, date, service) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/log-list`, {
    headers: getHeaders(token),
    params: {
      category: "error",
      date,
      service
    },
  });

  return response.data;
};


export const getErrorLogForSource = async (token, date, service, source) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/log-list`, {
    headers: getHeaders(token),
    params: {
      category: "error",
      date,
      service,
      source
    },
  });

  return response.data;
};

export const getActivityLogForSource = async (token, date, service, source) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/log-list`, {
    headers: getHeaders(token),
    params: {
      category: "activity",
      date,
      service,
      source
    },
  });

  return response.data;
};


export const getLogServices = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/log-services`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const getDocumentDetail = async (token, guid) => {
  const response = await axios.get(`${API_BASE_URL}/api/v4/documents/${guid}`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const getProfile = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/profile`, {
    headers: getHeaders(token)
  });

  return response.data;
};


export const callChatMessage = async (token, project_id, message) => {

  const response = await axios.post(`${API_BASE_URL}/api/v1/chat/message`,
      {
        project_id,
        message
      },
      {
        headers: getHeaders(token)
      });
  return response.data;

};


export const callInitExplain = async (token, doc_id) => {
  const response = await axios.post(`${API_BASE_URL}/api/v1/chat/message/init-explain`,
      {
        doc_id
      },
      {
        headers: getHeaders(token)
      });
  return response.data;
};




export const getGuestUsers = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/guest-users`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const addGuestUser = async (token, name, email, password) => {
  const data = {
    name,
    email,
    password
  }
  const response = await axios.post(`${API_BASE_URL}/api/v1/guest-users`, data,{
        headers: getHeaders(token)
      }
  );
  return response.data;
};

export const removeGuestUser = async (token, id) => {
  await axios.delete(`${API_BASE_URL}/api/v1/guest-users/${id}`, {
    headers: getHeaders(token)
  });
};


export const initNewChat = async (token) => {
  const data = {}
  const response = await axios.post(`${API_BASE_URL}/api/v1/chat/message/init`, data,{
        headers: getHeaders(token)
      }
  );
  return response.data;
};


export const getAugBatch = async (token, status) => {

  let config = {
    headers: getHeaders(token)
  }
  if (status) {
    config.params = {
      status
    }
  }
  const response = await axios.get(`${API_BASE_URL}/api/v1/aug-batch`, config);
  return response.data;
};

export const getAugBatchErrorLog = async (token, batch_id) => {

  let config = {
    headers: getHeaders(token)
  }
  const response = await axios.get(`${API_BASE_URL}/api/v1/aug-batch/${batch_id}/error-log`,
      config);
  return response.data;
};
