import React, {useEffect, useMemo, useState} from 'react';
import styles from './SearchViewer.module.css';
import {
    setSearchResults,
    useGlobalDispatch,
    useGlobalState,
    setLoading,
    setInfoMessage,
    modifyParameter,
} from "../../context/GlobalState";
import {useAuth} from "../../hooks/useAuth";
import { AiOutlineClose } from 'react-icons/ai';
import ReactJson from 'react-json-view';
import {
    FaAngleLeft, FaAngleRight,
    FaArrowLeft,
    FaArrowRight,
    FaCheckCircle,
    FaCircle,
    FaRegFileAlt,
    FaToggleOff,
    FaToggleOn, FaUndoAlt
} from "react-icons/fa";
import {
    createAndActivateRadar,
    getDocumentDetail,
    saveSearch,
    searchDocuments,
    searchDocuments4
} from "../../services/api";
import AggregationChart from "./AggregationChart";
import DocumentCard from "../DocumentCard/DocumentCard";
import {SectionTitle, SimpleSubTitle, SubSectionTitle, SubTitle} from "../Headings/Heading";
import {FaAnglesDown, FaAnglesLeft, FaAnglesRight, FaXmark} from "react-icons/fa6";
import MaxModal from "../MaxModal/MaxModal";
import {
    HiMiniChatBubbleOvalLeftEllipsis,
    HiMiniDocumentText,
    HiOutlineChatBubbleOvalLeftEllipsis
} from "react-icons/hi2";
import {MdOutlineFindInPage} from "react-icons/md";
import {LuUndo2} from "react-icons/lu";
import {BiEqualizer} from "react-icons/bi";
import queryHistory from "../../services/queryHistory";
import {FiSlash} from "react-icons/fi";
import {useNavigate} from "react-router-dom";


const SUMMARY_LIMIT = 140;

const aggOptions = [
    {code: "document_date", name: "data do documento"},
    {code: "index_date", name: "data indexada"},
    {code: "source", name: "fonte"},
    {code: "locations", name: "localidade"},
    {code: "subjects", name: "assunto"},
    {code: "entities", name: "ente"},
    {code: "authors", name: "autor"},
    {code: "main_topics", name: "tema principal"},
    {code: "sentiment", name: "sentimento"},
    {code: "author_handle", name: "author handle"},
]

// const AGG_BY_DOCUMENT_DATE = "document_date";
// const AGG_BY_SOURCE = "source";
// const AGG_BY_LOCATIONS = "locations";
// const AGG_BY_SUBJECTS = "subjects";
// const AGG_BY_ENTITIES = "entities";
// const AGG_BY_AUTHOR = "authors";
// const AGG_BY_MAIN_TOPICS = "main_topics";
// const AGG_BY_SENTIMENT = "sentiment";
//

const SearchViewer = () => {
    const dispatch = useGlobalDispatch();
    const {searchResults, filterUndoCount, isMobile, profile} = useGlobalState();
    const {user} = useAuth();
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState(0);
    const [detail, setDetail] = useState(null);
    // const [osQuery, setOsQuery] = useState(null);
    // const [loading, setLoading] = useState(false);
    const [aggregate, setAggregate] = useState([]);
    const [showHisto, setShowHisto] = useState(true);
    const [alertOn, setAlertOn] = useState(false);
    const [searchSaved, setSearchSaved] = useState(false);
    const [queryTotal, setQueryTotal] = useState(null);
    const [documentDetail, setDocumentDetail] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();



    useEffect(() => {
        if (searchResults) {
            let total = searchResults.results.hits?.total?.value || 0;
            if (total> 0) {
                // console.log('searchResults: ', JSON.stringify(searchResults));
                setResults(searchResults.results?.hits?.hits || []);
                setTotal(searchResults.results.hits?.total?.value);
                setAggregate(searchResults.results?.aggregations)
                setDetail(searchResults.results?.query);
                setAlertOn(false);
                setSearchSaved(false);
                setQueryTotal(searchResults.results.hits?.total);
            } else {
                setDetail(searchResults.results?.query);
                setQueryTotal(searchResults.results.hits?.total);
                dispatch(setInfoMessage("Nenhum resultado encontrado"));
            }
        }
    }, [searchResults]);



    const onSearch = async (from=0) => {
        try {
            dispatch(setLoading(true));
            const {  query, keywords, isUnion, size, filters, sortBy, url, title, isExactKeyword, aggregateBy } = searchResults;
            const results = await searchDocuments4(user.token, query, keywords, isUnion? "union": "and",
                isExactKeyword, url, title,
                filters,
                sortBy, aggregateBy, from, size);
            dispatch(setSearchResults({ ...searchResults, from, results}));
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading( false));
        }
    };

    const onSearchMore = async () => {
        const INCREMENT_SIZE = 10;
        try {
            dispatch(setLoading(true));
            const next_from = searchResults.from + searchResults.size
            const next_size = INCREMENT_SIZE;
            const {  query, keywords, isUnion, filters, sortBy, url, title, isExactKeyword, aggregateBy } = searchResults;
            let results = await searchDocuments4(user.token, query, keywords, isUnion? "union": "and",
                isExactKeyword, url, title,
                filters,
                sortBy, aggregateBy, next_from, next_size,
                true);  //no history

            console.log("searchResults.results?.hits?.hits", searchResults.results?.hits?.hits);
            console.log("results", results);
            console.log("results.results?.hits?.hits", results?.hits?.hits);
            const new_hits = {
                hits: [...searchResults.results.hits.hits,  ...results.hits.hits],
                total: {
                    value: results.hits.total.value,
                    relation: results.hits?.total?.relation
                }
            }
            console.log('from: ', searchResults.from, next_from);
            results = {...results, hits: new_hits} ;  //replace hits
            dispatch(setSearchResults({ ...searchResults, size:new_hits.hits.length, results}));
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading( false));
        }
    };


    const handleChangePage = async (step) => {
        // eslint-disable-next-line default-case
        switch (step) {
            case -9:
                await onSearch(0);
                break;
            case -1:
                await onSearch(Math.max(0, searchResults.from - searchResults.size));
                break;
            case 1:
                await onSearch(Math.max(0, searchResults.from + searchResults.size));
                break;
            case 9:
                await onSearch(Math.max(0, total - searchResults.size));
                break;
        }
    }

    const handleLoadMore = async ()=> {
        await onSearchMore();
    }

    const switchAggregate = async (aggregateBy) => {
        console.log(searchResults?.aggregateBy, '=> ', aggregateBy);
        if (searchResults?.aggregateBy == aggregateBy)
            return;
        try {
            dispatch(setLoading(true));
            const {  query, keywords, isUnion, size, filters, sortBy, url, title, from, isExactKeyword } = searchResults;
            const results = await searchDocuments4(user.token, query, keywords, isUnion? "union": "and",
                isExactKeyword, url, title,
                filters,
                sortBy, aggregateBy, from, size);
            dispatch(setSearchResults({ ...searchResults, aggregateBy, results}));
        } catch (error) {
            console.error('Error!', error);
        } finally {
            dispatch(setLoading( false));
        }
    }

    const handleSelectedOnAggregate = async (value) => {
        let aggregateBy = searchResults.aggregateBy;
        console.log( 'aggregatedBy', aggregateBy, 'value', value);
        if (aggregateBy !== "author_handle") {
            value = `"${value}"`
        }
        dispatch(modifyParameter({
            "aggregateBy": {
                name: aggregateBy,
                value: value
            }
        }))
    }

    const handleSelectDataRange = async (dataFrom, dataTo) => {
        const aggregateBy = searchResults.aggregateBy;
        console.log( 'aggregatedBy', aggregateBy, 'value', dataFrom, dataTo);
        dispatch(modifyParameter({
            "aggregateBy": {
                name: aggregateBy,
                value: [dataFrom, dataTo]
            }
        }))
    }

    const handleUndoOnAggregate = async (value) => {
        dispatch(modifyParameter({
            "undo": {}
        }))
    }

    const trimQuery = (query) => {
        const maxLength = 100;
        if (query && query.length > maxLength) {
            return query.substring(0, maxLength) + '...';
        }
        return query;
    };

    const renderMessage = () => {
        let message = `Encontrados ${total} artigos`;

        const hylightStyle = styles["highlight"]
        if (searchResults.keywords) {
            message += ` relacionados a <span class="${hylightStyle}">${searchResults.keywords}</span>`;
        }

        if (searchResults.query) {
            if (searchResults.keywords) {
                message += ` e`;
            } else {
                message += ` relacionados a `;
            }
            message += ` <span class="${hylightStyle}">${trimQuery(searchResults.query)}</span>`;
        }

        return message;
    };

    const handleToggleAlert = async () => {
        const {query, keywords, isUnion, size, filters, sortBy, aggregateBy, isExactKeyword, url} = searchResults;
        // const _filters = {...filters}
        // _filters['sentiment'] = Object.entries(_filters['sentiment'])
        //     .filter(([key, value]) => value === true)
        //     .map(([key]) => key);

        try {
            dispatch(setLoading(true));
            const _alert = await createAndActivateRadar(user.token, null, query, keywords,
                isUnion ? "union" : "and",
                isExactKeyword,
                url,
                filters);
            setAlertOn(true);
            alert(`Criado alerta para ${_alert?.name}. Confira na aba de alertas` )
        } catch (error) {
            alert(error.message);
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleSaveSearch = async () => {
        const {query, keywords, isUnion, size, filters, sortBy, aggregateBy, isExactKeyword, url} = searchResults;
        const _filters = {...filters}
        _filters['sentiment'] = Object.entries(_filters['sentiment'])
            .filter(([key, value]) => value === true)
            .map(([key]) => key);

        try {
            dispatch(setLoading(true));
            const _alert = await saveSearch(user.token, query, keywords, isUnion,
                isExactKeyword,
                url,
                _filters, sortBy, aggregateBy);
            setSearchSaved(true);
            alert(`Criado alerta para ${_alert?.name}. Confira na aba de alertas` )
        } catch (error) {
            alert(error.message);
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const searchParametersDiv = useMemo(()=> {
        if (searchResults) {
            let filters = null;
            if (searchResults?.filters) {
                filters = Object.entries(searchResults?.filters).reduce((acc, [key, value]) => {
                    // Exclude the 'results' field and filter out nullable values
                    if (
                        value !== null && // Exclude null
                        value !== undefined && // Exclude undefined
                        value !== "" && // Exclude empty strings
                        !(Array.isArray(value) && value.length === 0) // Exclude empty arrays
                    ) {
                        acc[key] = value; // Add the key-value pair to the target object
                    }
                    return acc;
                }, {});
            }

            const target = Object.entries(searchResults).reduce((acc, [key, value]) => {
                // Exclude the 'results' field and filter out nullable values
                if (
                    key !== "results" && // Exclude 'results'
                    key !== "filters" && // Exclude 'results'
                    value !== null && // Exclude null
                    value !== undefined && // Exclude undefined
                    value !== "" && // Exclude empty strings
                    !(Array.isArray(value) && value.length === 0) // Exclude empty arrays
                ) {
                    acc[key] = value; // Add the key-value pair to the target object
                }
                if (filters) {
                    acc["filters"] = filters;
                }
                return acc;
            }, {});

            return <ReactJson src={target} theme={"twilight"}/>
        } else {
            return null;
        }
    }, [searchResults])

    const detailContent = detail && (
        <div className={styles["main-content-right"]}>
            {/*<button*/}
            {/*    onClick={() => setDetail(null)}*/}
            {/*    className={styles['close-button']}*/}
            {/*    // style={{ background: 'none', border: 'none', cursor: 'pointer' }}*/}
            {/*>*/}
            {/*    <AiOutlineClose size={24}/>*/}
            {/*</button>*/}
            <SectionTitle>Agregado por...</SectionTitle>
            <div className={styles["aggregate-panel"]}>
                <div className={"select"}>
                    <select
                        name="aggregate_by"
                        onChange={(e) => switchAggregate(e.target.value)}
                        value={searchResults?.aggregateBy || ""}
                    >
                        {aggOptions.map((opt) => (
                            <option key={opt.code} value={opt.code}>
                                {opt.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles["aggregate-icons"]}>
                    <div className={styles["undo-icon"]}>
                        <div className={styles["filter-undo-count"]}>{filterUndoCount}</div>
                        <div className={`fa-icon ${styles["document-list-panel"]}`}
                             onClick={() => handleUndoOnAggregate()}>
                            <FaUndoAlt />
                        </div>
                    </div>
                    <div className={`fa-icon ${styles["document-list-panel"]}`}
                         onClick={(e) => setShowModal(true)}>
                        <HiMiniDocumentText/>
                    </div>
                </div>
            </div>
            <AggregationChart new_aggregate={aggregate}
                              handleSelectDataRange = {handleSelectDataRange}
                              handleClick={handleSelectedOnAggregate}/>
            <div className={"top-border"}>
                <SimpleSubTitle>Search parameters</SimpleSubTitle>
            </div>
            {searchParametersDiv}
            <div className={styles["toggle-container"]}>
                <div className={styles["toggle-container-option"]}>
                    <span>
                                  set Alert
                                </span>
                    <div className={styles["toggle-switch"]} onClick={() => handleToggleAlert(!alertOn)}>
                        {alertOn ? (
                            <FaToggleOn size={30} color="#cda869"/>
                        ) : (
                            <FaToggleOff size={30}/>
                        )}
                    </div>
                </div>
                <div className={styles["toggle-container-option"]}>
                    <span>
                                  salvar busca
                                </span>
                    <div className={styles["toggle-switch"]} onClick={() => handleSaveSearch()}>
                        {searchSaved ? (
                            <FaToggleOn size={30} color="#cda869"/>
                        ) : (
                            <FaToggleOff size={30}/>
                        )}
                    </div>
                </div>
            </div>
            <div className={"top-border"}>
                <SimpleSubTitle>Total</SimpleSubTitle>
            </div>
            <div className={"top-border"}>
                <ReactJson src={queryTotal} theme={"twilight"}/>
            </div>
            <div className={"top-border"}>
                <SimpleSubTitle>Search result or Document detail</SimpleSubTitle>
            </div>
            <div className={styles['detail']}>
                <ReactJson src={detail} theme={"twilight"}/>
                {/*<JsonView data={detail} shouldExpandNode={allExpanded}/>*/}
                {/*<div>{JSON.stringify(detail)}</div>*/}
            </div>
        </div>
    );

    if (!results || results.length <= 0) {
        return (
            <div className={styles["main-content"]}></div>
        );
    }

    const pageButtons = (
        <>
            <button
                className={styles["prev-button"]}
                onClick={() => handleChangePage(-9)}
                disabled={searchResults.from === 0}
            >
                <FaAnglesLeft/>
            </button>
            <button
                className={styles["prev-button"]}
                onClick={() => handleChangePage(-1)}
                disabled={searchResults.from === 0}
            >
                <FaAngleLeft/>
            </button>

            <button
                className={styles["next-button"]}
                onClick={() => handleChangePage(1)}
                disabled={searchResults.from + searchResults.size >= total}
            >
                <FaAngleRight/>
            </button>
            <button
                className={styles["next-button"]}
                onClick={() => handleChangePage(9)} // Placeholder for next page handler
                disabled={searchResults.from + searchResults.size >= total} // Disable if on the last page
            >
                <FaAnglesRight/>
            </button>
        </>
    )

    const mainList = <div className={styles["main-content-left"]}>
        <div className={styles["result-bar"]}>
            {/*{loading && <div className={"loading"}>loading..</div>}*/}

            <div className={styles["encontrados"]} dangerouslySetInnerHTML={{__html: renderMessage()}}/>
            <div className={styles["result-panel"]}>
                <div>Exibidos {searchResults.from} a {searchResults.from + results.length} artigos</div>
                <div className={styles["history-back"]}>
                    <div
                        className={`fa-icon -bluesh`}
                        onClick={() => {
                            dispatch(modifyParameter({
                                resetHistory: {}
                            }))
                        }}
                    >
                        <FiSlash/>
                    </div>
                    <div className={styles["history-undo"]}>
                        <div className={styles["history-size"]}>
                            {queryHistory.size() - 1}
                        </div>
                        <div
                            className={`fa-icon -bluesh`}
                            onClick={() => {
                                dispatch(modifyParameter({
                                    backHistory: {}
                                }))
                            }}
                        >
                            <LuUndo2/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles["pagination-controls"]}>
                {pageButtons}
            </div>

        </div>
        <div className={styles["result-list"]}>
            {results.map((result, index) => (
                <div className={styles["document-list-line"]}>
                    <DocumentCard
                        key={index}
                        result={result}
                        onClick={setDetail}
                        filters={searchResults.filters}
                    />
                    <div className={`${styles["document-list-panel"]}`}>
                        <div className={`fa-icon -smaller-x`}
                             title={'view document'}
                             onClick={() => handleShowDocument(result._id)}>
                            <HiMiniDocumentText/>
                        </div>
                        <div className={`fa-icon -smaller-x`}
                             title={'like this..'}
                             onClick={() => handleLikeThis(result._id)}>
                            <BiEqualizer/>
                        </div>
                        {profile?.preview_enabled && <div className={`fa-icon -smaller-x`}
                             title={'Explain this article..'}
                             onClick={() => handleExplainThis(result._id)}>
                            <HiOutlineChatBubbleOvalLeftEllipsis  />
                        </div>}
                    </div>
                </div>
            ))}
            <div className={styles["end-of-list-panel"]}>
                <div
                    className={"fa-icon"}
                    onClick={() => handleLoadMore()}
                >
                    <FaAnglesDown/>
                </div>
            </div>
        </div>
        {/*{isMobile && <div>*/}
        {/*    {pageButtons}*/}
        {/*</div>}*/}
    </div>


    const handleShowDocument = async (id) => {
        try {
            dispatch(setLoading(true));
            const result = await getDocumentDetail(user.token, id);
            setDocumentDetail(result);
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading( false));
        }
    }

    const handleLikeThis = async (id) => {
        try {
            dispatch(modifyParameter({
                likeThis: {
                    guid: id
                }
            }))
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    }

    const handleExplainThis = async (id) => {
        navigate(`/chat?doc_id=${id}`);
    }

    const documentModal = documentDetail &&  (
        <MaxModal
            handleClose={()=> setDocumentDetail(null)}
        >
            <div className={styles["document-modal-container"]}>
                <ReactJson src={documentDetail} theme={"twilight"}/>
            </div>
        </MaxModal>
    )

    const aggregateModal = showModal && aggregate && (
        <MaxModal
            handleClose={() => setShowModal(false)}
        >
            <div className={styles["error-list-container"]}>
                <ReactJson src={aggregate} theme={"twilight"}/>
            </div>
        </MaxModal>
    )

    return (<>
            <div className={styles["main-container"]}>
                {mainList}
                {detailContent}
            </div>
            {documentModal}
            {aggregateModal}
        </>
    );
};

export default SearchViewer;
