import styles from './MonitorQueue.module.css'
import React, {useEffect, useRef, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {
    getQueueCount
} from "../../services/api"
import {setLoading, useGlobalDispatch} from "../../context/GlobalState";
import {TfiReload} from "react-icons/tfi";

const MonitorQueue = () => {
    const dispatch = useGlobalDispatch();
    const [queueCount, setQueueCount] = useState([]);
    const { user } = useAuth();
    const [fetchError, setFetchError] = useState(null); // State to track fetch errors
    const hasFetchedData = useRef(false); // Ref to track if data has been fetched

    useEffect(() => {
        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true; // Mark as fetched
        }
    }, []);

    const fetchData = async () => {
        try {
            dispatch(setLoading(true));
            const queueCountResult = await getQueueCount(user.token)
            setQueueCount( queueCountResult);
        } catch (error) {
            console.error("Error fetching data:", error);
            setFetchError("Failed to fetch data. Please try again later.");
        } finally {
            dispatch(setLoading(false));
        }
    }

    const reloadQueue = async () => {
        try {
            dispatch(setLoading(true));
            const queueCountResult = await getQueueCount(user.token)
            setQueueCount( queueCountResult);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const zeroAsNull = (d) => {
        return d===0?'-':d
    }

    return (
        <div className={styles["container"]}>
            <div className={styles["data-container"]}>
                {fetchError && <div className={styles["error-message"]}>{fetchError}</div>}
                <h2>Queue Counts</h2>
                <div className={styles["icons-panel"]}>
                    <div className={"fa-icon -smaller-x"}
                         onClick={(e) => {
                             reloadQueue()
                         }}>
                        <TfiReload/>
                    </div>
                </div>
                <div className={styles["list-container"]}>
                    {queueCount.map((_item, index) => (<div className={styles["queue-row"]}>
                        <div className={styles["queue-name"]}>
                            {_item.name}
                        </div>
                        <div className={styles["queue-count"]}>
                            {zeroAsNull(_item.count)}
                        </div>
                    </div>))}
                </div>

            </div>
        </div>
    )
}

export default MonitorQueue;
