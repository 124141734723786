import React, {useEffect, useMemo, useRef, useState} from "react";
import styles from './Chat.module.css';
import {FaAngleLeft, FaAngleRight, FaArrowUp} from "react-icons/fa";
import {API_BASE_URL, getDocumentDetail, initNewChat} from "../../services/api";
import {useAuth} from "../../hooks/useAuth";
import {useNavigate, useSearchParams} from "react-router-dom"
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import FullMarkdown from "../FullMarkdown/FullMarkdown";
// import EventSource from "eventsource-polyfill";
// import { EventSourcePolyfill } from "eventsource-polyfill"; // Install this library: npm install eventsource-polyfill

const getHeaders = (token) => ({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
});


const convertReferencesToLinks = (text) => {
    // Regular expression to match \reference{<url>,<url>}
    // const referenceRegex = /\\reference\{([^}]+)}/g;
    const referenceRegex = /\{\{([^}]+)}}/g;

    // Replace the matched references with HTML links
    return text.replace(referenceRegex, (match, urls) => {
        // Split the URLs by comma
        const urlArray = urls.split(",").map((url) => url.trim());

        // Generate HTML links for each URL
        return urlArray
            .map((url, index) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`)
            .join(", ");
    });
};

const formatChatThread = (messages, isDisableFormat) => {
    return messages.map((message, index) => {
        if (message.role === "assistant" && !isDisableFormat) {
            return {
                ...message,
                formatted: <div className={styles["markdown-parent"]}><FullMarkdown content={message.content}/></div>
            }
        } else {
            return message
        }
    })
};



const Chat = () => {
    const [thread, setThread] = useState(null);
    const [prompt, setPrompt] = useState('');
    const { user } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams('');
    const docId = searchParams.get('doc_id');
    const hasInitialized = useRef(false);
    const [streamedResponse, setStreamedResponse] = useState("");
    const [messages, setMessages] = useState([]);
    const [chatId, setChatId] = useState(null);
    const lastUserMessageRef = useRef(null);
    const [chatLoading, setChatLoading] = useState(false);
    const [docDetail, setDocDetail] = useState(null);
    const [isDisableFormat, setDisableFormat] = useState(false);
    const [serverStatusFeedback, setServerStatusFeedback] = useState('');
    useEffect(()=> {
        console.log( 'chat called!');
    }, []);

    useEffect(()=> {
        if (!hasInitialized.current) {
            hasInitialized.current = true; // Set the flag to true
            if (docId) {
                console.log('Initializing chat with document:', docId);
                initExplainStream(docId);
            } else {
                console.log('Initializing empty chat', docId);
                initEmptyChat();
            }
        }
    }, [docId]);

    useEffect(()=> {
        // waiting the assistant answer
        if (messages.length>0 && messages[messages.length-1].content==='') {
            if (lastUserMessageRef.current) {
                lastUserMessageRef.current.scrollIntoView({
                    behavior: "smooth", // Smooth scrolling
                    block: "start", // Align the message to the top of the container
                });
            }
        }

    }, [messages])


    useEffect(()=> {
        if(streamedResponse) {
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages]
                updatedMessages[updatedMessages.length-1] = {
                    ...updatedMessages[updatedMessages.length-1],
                    content: streamedResponse
                }
                return updatedMessages
            })
        }
    }, [streamedResponse])

    const formattedMessages = useMemo(() => formatChatThread(messages, isDisableFormat), [messages, isDisableFormat]);


    const render_reference = (reference_list) => {
        return (
            <div
                className={styles["reference-list"]}
                // onClick={() => alert("TODO: show reference list..")}
            >
                <div>{reference_list.length} documentos relevantes encontrados</div>
                <div className={styles["reference-docs"]}>
                    {reference_list.map((_ref, index) => (
                        <div className={styles["reference-one-doc"]}>
                            {_ref?.source}&nbsp;&nbsp;|&nbsp;&nbsp;
                            {_ref?.url && <a href={_ref.url}>{_ref.title}</a>}
                            {!_ref?.url && _ref?.title}
                        </div>
                    ))}
                </div>
                {/*<div className={`fa-icon ${styles["reference-open-icon"]}`}>*/}
                {/*    <FaAngleRight />*/}
                {/*</div>*/}
            </div>
        )
    }

    const divThread = (<div className={styles["thread-container"]}>
        {messages.length <= 0 && !docId && <div className={styles["greeting"]}>
            <div>Olá, como posso te ajudar?</div>
        </div>}
        {messages.length> 0 && <div
            className={styles["chat-lines-container"]}
            style={{whiteSpace: "pre-wrap"}}
        >
            {/*{messages.map((message, index) => {*/}
            {formattedMessages.map((message, index) => {

                const isLastUserMessage =
                    message.role === "user" &&
                    index === messages.length - 2;
                const isLastAssistantMessage =
                    message.role === "assistant" &&
                    index === messages.length - 1;

                return <div
                    key={index}
                    ref={isLastUserMessage ? lastUserMessageRef : null}
                    className={`${styles["chat-card"]} ${styles["role-" + message.role]}`}
                >

                    {/*<div className={styles["role"]}>{message.role}</div>*/}
                    <div className={styles["content"]}>
                        {message?.reference_list && render_reference(message.reference_list)}
                        {message.role === "assistant" && message.formatted?
                            message.formatted: message.content}
                            {/*<p dangerouslySetInnerHTML={{__html: convertReferencesToLinks(message.content)}}/> : message.content}*/}
                        {chatLoading && isLastUserMessage && <div className={`fa-icon ${styles['inline-loading-icon']}`}>
                            <div className={'loading-anim'}><AiOutlineLoading3Quarters/></div>
                        </div>}
                        {chatLoading && isLastAssistantMessage && !message.content && serverStatusFeedback && (
                            <div className = {styles["server-side-status"]}>{serverStatusFeedback}</div>)}
                    </div>
                </div>}
            )}
        </div>}
    </div>)

    const handlePromptKeyDown = async (event) => {
        if (event.ctrlKey && event.key === 'Enter') {
            // Prevent the default action to avoid any unwanted behavior
            event.preventDefault();
            await onSend();
        }
    }

    // const initExplain = async () => {
    //     const response = await callInitExplain(user.token, docId);
    //     setThread(response?.response);
    // }


    // const initExplainStream = async () => {
    //     setStreamedResponse(""); // Clear previous response
    //
    //     const headers = getHeaders(user.token);
    //
    //     // Use EventSourcePolyfill to pass custom headers
    //     const eventSource = new EventSource(
    //         `${API_BASE_URL}/api/v1/chat/message/stream/init-explain`, // Replace with your actual endpoint
    //         {
    //             headers,
    //         }
    //     );
    //
    //     eventSource.onmessage = (event) => {
    //         // Append the streamed content to the response
    //         setStreamedResponse((prev) => prev + event.data);
    //     };
    //
    //     eventSource.onerror = (error) => {
    //         console.error("Error with EventSource:", error);
    //         eventSource.close();
    //     };
    //
    //     // Close the connection when the stream ends
    //     eventSource.addEventListener("end", () => {
    //         eventSource.close();
    //     });
    // };


    const initEmptyChat = async () => {
        const newChat = await initNewChat()
        setChatId(newChat.chat_id);
        console.log('chatId: ', newChat.chat_id);
    }

    const initExplainStream = async () => {

        try {
            setChatLoading(true);

            // get the document
            const result = await getDocumentDetail(user.token, docId);
            setDocDetail(result);

            // create new chat
            const newChat = await initNewChat()
            setChatId(newChat.chat_id);
            console.log('chatId: ', newChat.chat_id);

            setMessages((prevMessages) => [
                ...prevMessages, {role: "assistant", content: ''}
            ]);


            setStreamedResponse(""); // Clear previous response
            const headers = getHeaders(user.token);
            const response = await fetch(`${API_BASE_URL}/api/v1/chat/message/${newChat.chat_id}/stream/explain`, {
                method: "POST",
                headers,
                body: JSON.stringify({
                    doc_id: docId
                }),
                // JSON.stringify({ messages: [{ role: "user", content: input }] }),
            });

            await receive_stream(response);
        } finally {
            setChatLoading(false);
        }

    };

    const handleStructureChunk = (_type, data) => {
        console.log( 'Received: ', _type, data)
        if (_type === "status") {
            setServerStatusFeedback(data)
        } else if (_type === "reference_doc_list") {
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages]
                updatedMessages[updatedMessages.length-1] = {
                    ...updatedMessages[updatedMessages.length-1],
                    reference_list: data
                }
                return updatedMessages
            })
        }
    }

    const receive_stream = async (response) => {
        setStreamedResponse(""); // Clear previous response

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        const chunkList = [];
        let lastFlush = Date.now();
        while (true) {
            // console.log('wait read()..');
            const { done, value } = await reader.read();
            // console.log('received!', done,value);
            if (done) {
                // Flush any remaining chunks when the stream ends
                if (chunkList.length > 0) {
                    const newChunk = chunkList.join('');
                    setStreamedResponse((prev) => prev + newChunk);
                }
                break;
            }

            // Decode the chunk and add it to the chunk list
            const chunkJsonList = decoder.decode(value);
            const chunkLines = chunkJsonList.split("\n").filter(line => line.trim() !== ""); // Handle SSE format
            for (const jsonLine of chunkLines) {
                const chunkObj = JSON.parse(jsonLine)
                if (chunkObj.type === "chunk") {
                    let chunk = chunkObj.data;
                    chunkList.push(chunk);

                    // Check if it's time to flush the chunks
                    const curTime = Date.now();
                    if (curTime - lastFlush > 250) {
                        const newChunk = chunkList.join('');
                        chunkList.length = 0; // Clear the chunk list
                        setStreamedResponse((prev) => prev + newChunk);
                        lastFlush = curTime; // Update the last flush time
                    }
                } else {
                    handleStructureChunk(chunkObj.type, chunkObj.data)
                }
            }
        }
    }

    const onSend = async () => {
        try {
            setChatLoading(true);

            // add prompt to history
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    role: "user",
                    content: prompt
                },
                {
                    role: "assistant",
                    content: ''
                }
            ])

            // send new message
            setStreamedResponse(""); // Clear previous response
            const headers = getHeaders(user.token);
            const response = await fetch(`${API_BASE_URL}/api/v1/chat/message/${chatId}/stream`, {
                method: "POST",
                headers,
                body: JSON.stringify({
                    message: prompt
                }),
                // JSON.stringify({ messages: [{ role: "user", content: input }] }),
            });
            setPrompt('');

            await receive_stream(response)
        } finally {
            setChatLoading(false);
        }
    }

    const divPrompt = (<div className ={`${styles["prompt-container"]} ${chatLoading?styles["transparent"]:''}`}>
        <textarea
            className={styles["prompt-textarea"]}
            value = {prompt}
            onChange = {e=> setPrompt(e.target.value)}
            onKeyDown={handlePromptKeyDown}
        ></textarea>
        <div className={styles["prompt-send-icon"]}
        >
            <div className={`fa-icon ${styles["circle-button"]}`}
                    onClick={() => onSend()}
                    title='use também CTRL+ENTER nas campos de input'
            >
                {chatLoading? (<div className={'loading-anim'}><AiOutlineLoading3Quarters /></div>): <FaArrowUp/>}
            </div>
        </div>
    </div>)

    const divThreadTitle = docDetail && (
        <div className={styles["thread-title-doc"]}>
            <div>{docDetail?.title}</div>
            <div><a href={docDetail?.url}>{docDetail?.url}</a></div>
        </div>
    )

    return <div className={styles["container"]}>
        <div className={`fa-icon ${styles["back"]}`}
             onClick={()=> navigate(-1)}>
            <FaAngleLeft/>
        </div>
        {divThreadTitle}
        {divThread}
        {divPrompt}
    </div>
}
export default Chat;
