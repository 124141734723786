import styles from "./NavBar.module.css";
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import {LuRadar} from "react-icons/lu";
import {MdMonitor, MdOutlineDraw, MdQueryStats} from "react-icons/md";
import {FaHistory, FaSearch} from "react-icons/fa";
import {IoChatbubbleEllipses, IoLogOutSharp} from "react-icons/io5";
import {setLoading, useGlobalDispatch, useGlobalState} from "../../context/GlobalState";
import {getProfile, getQueueCount} from "../../services/api";
import {FaChalkboardUser} from "react-icons/fa6";
import {useAuth} from "../../hooks/useAuth";
import {
    setProfile
} from "../../context/GlobalState";


const NavBar = () => {

    const {searchResults, filterUndoCount, isMobile, profile} = useGlobalState();
    // const [profile, setProfile] = useState({})
    const { user, logout } = useAuth();
    const dispatch = useGlobalDispatch();


    useEffect(()=> {
        const load = async ()=> {
            try {
                const profile = await getProfile(user?.token);
                // setProfile(profile);
                dispatch(setProfile(profile));
                console.log('profile', profile)
            } catch(err) {
                console.log(err);
            }
        }
        load();
    }, [])

    return (
        <div className={styles["nav-container"]}>
            <div className={styles["command-icons"]}>
                <div className={styles["logo"]}>
                    <img
                        src="/android-chrome-512x512.png"
                        alt="App Logo"
                        className={styles["app-logo"]}
                    />
                </div>
                <Link to="/plain-query" className={"fa-icon"}
                      title={"opensearch plain query"}> {/* Navigate to Search */}
                    <MdOutlineDraw/>
                </Link>
                <Link to="/search" className={"fa-icon"} title={"search"}> {/* Navigate to Search */}
                    <FaSearch/>
                </Link>
                <Link to="/saved-search" className={"fa-icon"} title={"buscas salvas"}> {/* Navigate to Radar */}
                    <FaHistory/>
                </Link>
                <Link to="/alert" className={"fa-icon"} title={"alertas"}> {/* Navigate to Radar */}
                    <LuRadar/>
                </Link>
                {profile?.preview_enabled && (
                    <Link to="/chat" className={"fa-icon"} title={"chat"}> {/* Navigate to Radar */}
                        <IoChatbubbleEllipses/>
                    </Link>)}
                <Link to="/monitor" className={"fa-icon"} title={"system monitor"}> {/* Navigate to Search */}
                    <MdMonitor/>
                </Link>
                {profile?.is_super && <>
                    <Link to="/guest-user" className={"fa-icon -redsh"} title={"guest user"}> {/* Navigate to Search */}
                        <FaChalkboardUser/>
                    </Link>
                </>}
                <div className={"fa-icon"} title={"logout"}
                     onClick={() => {
                         if (window.confirm('Logout?')) {
                             logout();
                         }
                     }}
                > {/* Navigate to Search */}
                    <IoLogOutSharp/>
                </div>
            </div>
        </div>

    )
}

export default NavBar;
