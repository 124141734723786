import styles from "./MaxModal.module.css"
import React, {useContext, useEffect, useState} from "react";
// import {ThemeContext} from "../../redux/ThemeContext";
// import { AppContext } from '../../redux/AppContext'; // import AppContext
// import {setUseMaximize} from "../../redux/actions";
// import {setSearchResults, useGlobalDispatch, useGlobalState, setLoading} from "../../context/GlobalState";
import {FaXmark} from "react-icons/fa6";
// import {FaCompressAlt, FaExpandAlt} from "react-icons/fa";
import {useGlobalDispatch} from "../../context/GlobalState";

function MaxModal({ handleClose, handleSave, show, children, closeLabel }) {
    // const [useMaximize, setMaximize] = useState(false);
    // const { theme } = useContext(ThemeContext);
    const dispatch = useGlobalDispatch();
    // const { state, dispatch } = useContext(AppContext);
    // const { isMobile, useMaximize } = state;

    // const showHideClassName = show ? `${styles["modal"]} ${styles["display-block"]}` :
    //     `${styles["modal"]} ${styles["display-none"]}`;

    return (
        <div className={styles["modal"]} onMouseDown={handleClose}>
            <div className={`${styles["modal-container-maximize"]}`}
                 onMouseDown={(e)=> e.stopPropagation()}>
                <div className={styles["modal-nav"]}>
                    <div className="fa-icon -larger-x" onClick={handleClose}>
                            <FaXmark/>
                    </div>
                </div>
                <div className={styles["modal-container-inner"]}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default MaxModal;

