import styles from './GuestUser.module.css'
import React, {useEffect, useRef, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {
    getGuestUsers,
    addGuestUser, removeGuestUser
} from "../../services/api"
import {setInfoMessage, setLoading, useGlobalDispatch} from "../../context/GlobalState";
import {Title, SubSectionTitle} from "../Headings/Heading";
import {FaClock, FaEye, FaEyeSlash, FaRegTrashCan} from "react-icons/fa6";
import {LuTimerReset} from "react-icons/lu";
import ReactJson from "react-json-view";
import MaxModal from "../MaxModal/MaxModal";
import {FaArrowRight} from "react-icons/fa";
import {TfiReload} from "react-icons/tfi";



const GuestUser = () => {
    const dispatch = useGlobalDispatch();
    const {user} = useAuth();
    const [errorMessage, setErrorMessage] = useState(null);
    const [password1, setPassword1] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [showPassword1, setShowPassword1] = useState(true);
    const [showPassword2, setShowPassword2] = useState(true);
    const [users, setUsers] = useState([]);
    const [isWorking, setWorking] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);
    //
    const fetchUsers = async () => {
        try {
            const response = await getGuestUsers(user.token);
            setUsers(response);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const handleAddUser= async (id, comment) => {
        setErrorMessage(null);
        if (password1 !== password2) {
            setErrorMessage("password don't match");
            return;
        }
        try {
            dispatch(setLoading(true));
            await addGuestUser(user?.token, name, email, password1);
            dispatch(setInfoMessage("User added successfully"));
            setEmail('');
            setName('');
            setPassword1('');
            setPassword2('');
            await fetchUsers();
        } catch(error) {
            setErrorMessage(error.toString());
        } finally {
            dispatch(setLoading(false));
            setPassword1('');
            setPassword2('');
        }
    }

    const handleDeleteUser = async (id) => {
        if (!window.confirm( "Remove user?")) return;
        setErrorMessage(null);
        try {
            dispatch(setLoading(true));
            await removeGuestUser(user?.token, id);
            dispatch(setInfoMessage("User removed successfully"));
            await fetchUsers();
        } catch(error) {
            setErrorMessage(error.toString());
        } finally {
            dispatch(setLoading(false));
        }

    }

    const userList = users.length> 0 && <>
            <div className={styles.projectList}>
                <div key={'title'} className={`${styles["projectItem"]} list-item-title`}>
                    <div>Name</div>
                    <div>Email</div>
                </div>
                {users.map((user, idx) =>
                    <div key={idx} className={`${styles["projectItem"]} list-item`}>
                        <div>{user.name}</div>
                        <div>{user.email}</div>
                        <div>
                            <div className={styles["action-button"]}
                                 onClick={() => handleDeleteUser(user.id)}
                                 title={"remove user"}
                            ><FaRegTrashCan/></div>
                        </div>
                    </div>)
                }
            </div>
        </>

    return (
        <div className={styles['container']}>
            <Title>Add guest users</Title>
            <div className={styles["password-update-form"]}>
                <div className={styles["form-group"]}>
                    <div>Name:</div>
                    <div className={styles["password-input-container"]}>
                        <input
                            type={"text"}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className={styles["form-group"]}>
                    <div>Email:</div>
                    <div className={styles["password-input-container"]}>
                        <input
                            type={"email"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className={styles["form-group"]}>
                    <div>Password:</div>
                    <div className={styles["password-input-container"]}>
                        <input
                            type={showPassword1 ? "text" : "password"}
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                            required
                        />
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => setShowPassword1(!showPassword1)} // Toggle visibility*/}
                        {/*    className={styles["toggle-password-btn"]}*/}
                        {/*>*/}
                        {/*    <div className={styles["icons"]}>*/}
                        {/*        {showPassword1 ? <FaEyeSlash/> : <FaEye/>}*/}
                        {/*    </div>*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div className={styles["form-group"]}>
                    <div>Repeat password:</div>
                    <div className={styles["password-input-container"]}>
                        <input
                            type={showPassword2 ? "text" : "password"}
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            required
                        />
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => setShowPassword2(!showPassword2)} // Toggle visibility*/}
                        {/*    className={styles["toggle-password-btn"]}*/}
                        {/*>*/}
                        {/*    <div className={styles["icons"]}>*/}
                        {/*        {showPassword2? <FaEyeSlash/>: <FaEye/>}*/}
                        {/*    </div>*/}
                        {/*</button>*/}
                    </div>
                </div>
                <button type="submit" className={`${styles["submit-btn"]} button`} disabled={isWorking}
                        onClick={handleAddUser}
                >
                    {isWorking ? 'Working...' : 'Add user'}
                </button>

            </div>
            {errorMessage && <div className={styles["error-message"]}>{errorMessage}</div>}
            <hr/>
            {userList}
        </div>
    );
}

export default GuestUser;
