import React, {useEffect, useState} from 'react';
import {
    FaArrowRight,
    FaCheck,
    FaCheckCircle, FaChevronDown,
    FaCircle,
    FaHistory,
    FaSearch,
    FaToggleOff,
    FaToggleOn
} from 'react-icons/fa';
import styles from './SearchPanel.module.css';
import {
    createAndActivateRadar, getDocumentDetail,
    getIndexes,
    getSavedSearchList,
    searchDocuments,
    searchDocuments4
} from "../../services/api";
import {
    setSearchResults,
    useGlobalDispatch,
    useGlobalState,
    setLoading,
    modifyParameter, setFilterUndoCount
} from "../../context/GlobalState";
import {useAuth} from "../../hooks/useAuth";
import ContextModal from "../ContextModal/ContextModal";
import SearchViewer from "../SearchViewer/SearchViewer";
import {SectionTitle, SubSectionTitle} from "../Headings/Heading";
import {Link} from "react-router-dom";
import {MdMonitor, MdOutlineClear, MdOutlineDraw} from "react-icons/md";
import {LuRadar} from "react-icons/lu";
import {IoChatbubbleEllipses} from "react-icons/io5";
import {FaCircleInfo, FaInfo, FaSliders, FaClock, FaChevronUp} from "react-icons/fa6";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css'; // Import the styles for the date picker
import queryHistory from "../../services/queryHistory";
import ReactJson from "react-json-view";
import MaxModal from "../MaxModal/MaxModal";

const sortOptions = [
    {code: "relevance", name: "relevância (score)"},
    {code: "date-desc", name: "data da publicação - desc"},
    {code: "date-asc", name: "data da publicação - asc"},
    {code: "index", name: "data indexada - desc"},
]
const aggOptions = [
    {code: "document_date", name: "data do documento"},
    {code: "index_date", name: "data indexada"},
    {code: "source", name: "fonte"},
    {code: "locations", name: "localidade"},
    {code: "subjects", name: "assunto"},
    {code: "entities", name: "ente"},
    {code: "authors", name: "autor"},
    {code: "main_topics", name: "tema principal"},
    {code: "sentiment", name: "sentimento"},
    {code: "author_handle", name: "social network author handle"},
]

let cachedIndexes = null;
let onetimeSearchTimestamp = null;

const INDEX_NAME = "documents4_4"

const filterHistory = [];


const removeNullKeys = (data) => {
    if (Array.isArray(data)) {
        return data
            .map((item) => removeNullKeys(item)) // Recursively process array elements
            .filter((item) => !!item); // Remove null elements
    } else if (data && typeof data === "object") {
        return Object.entries(data)
            .filter(([_, value]) => !!value) // Filter out keys with null values
            .reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: removeNullKeys(value), // Recursively process nested objects
                }),
                {}
            );
    }
    return data; // Return the value if it's not an object or array
};


const help_for_keyword = (
    <div>
        <ul>
            <li>+ and - operator: <br/>Ex: +banana -abacaxi</li>
            <li>boost the relevance score. <br/>Ex:
                banana^2 caqui
            banana^0 caqui</li>
            <li>AND, &&, OR, ||, NOT, !
            <br/>Ex: ((gone AND wind) OR wind) AND NOT turbines</li>
            <li>~ operator: fuzzy
                <br/>Ex: rise~</li>
        </ul>
        <a href={"https://opensearch.org/docs/latest/query-dsl/full-text/query-string/#query-string-syntax"}>{"https://opensearch.org/docs/latest/query-dsl/full-text/query-string/#query-string-syntax"}</a>
    </div>
)


// Utility function to calculate the date 40 days ago
const getLastNDaysDate = (N) => {
    const today = new Date();
    const lastNDays = new Date(today.setDate(today.getDate() - N));
    return lastNDays.toISOString().split('T')[0]; // Format as YYYY-MM-DD
};

const SearchPanel = () => {
    const dispatch = useGlobalDispatch();
    const { searchResults, parametersToModify } = useGlobalState();
    const { user } = useAuth();
    const [ advancedSearch, setAdvancedSearch ] = useState(false);
    const [query, setQuery] = useState('');
    const [keywords, setKeywords] = useState('');
    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');
    const [isUnion, setIsUnion] = useState(false); // Default is 'UNION'
    const [isExactKeyword, setExactKeyword] = useState(false);
    const [sortBy, setSortBy] = useState("date-desc");
    const [aggregateBy, setAggregateBy] = useState("document_date");
    const {loading} = useGlobalDispatch();
    // const [loading, setLoading] = useState(false);
    const [savedSearchList, setSavedSearchList] = useState([]);
    const [showHelp, setShowHelp] = useState(null);
    const [triggerSearch, setTriggerSearch] = useState(null);
    const [filters, setFilters] = useState({
        source: '',
        publish_datetime_from: getLastNDaysDate(40),
        publish_datetime_until: '',
        index_datetime_from: '',
        index_datetime_until: '',
        sentiment: {
            neutral: false,
            positive: false,
            negative: false,
        },
        main_topics: '',
        author_handle: '',
        subjects: '',
        entities: '',
        authors: '',
        locations: '',
    });

    const [showSaved, setShowSaved] = useState(null);
    const [showHistory, setShowHistory] = useState(null);
    const [simpleSearch, setSimpleSearch] = useState(true);

    const [indexes, setIndexes] = useState(cachedIndexes);

    useEffect(() => {
        async function fetchData() {
            if (!cachedIndexes) {
                const newIndexes = await getIndexes(user.token);
                cachedIndexes = newIndexes;
                console.log('cachedIndexes', cachedIndexes);
                setIndexes(newIndexes);
            }
        }
        fetchData();
    }, []);


    useEffect(()=> {
        async function search() {
            if (!onetimeSearchTimestamp) {
                await onSearch();
                onetimeSearchTimestamp = new Date();
            }
        }
        search();
    }, [])

    useEffect(()=> {
        console.log('triggerSearch', triggerSearch);
        if (!triggerSearch) return;
        async function search(nohistory) {
            await onSearch(nohistory);
        }
        search(triggerSearch.startsWith("nohist"));
    }, [triggerSearch]);

    useEffect(()=> {
        loadSavedSearch();
    }, [])


    useEffect(() => {
        if (searchResults?.aggregateBy !== aggregateBy) {
            setAggregateBy(searchResults?.aggregateBy)
        }
    }, [searchResults]);

    useEffect (()=> {
        const apply = async (parametersToModify)=> {
            console.log('modify parameter received!', parametersToModify);
            if (!parametersToModify) return;

            if (parametersToModify?.undo) {
                console.log( "undo", filterHistory.length);
                if (filterHistory.length > 0) {
                    setFilters(filterHistory.pop());
                    dispatch(setFilterUndoCount(filterHistory.length));
                    setTriggerSearch(new Date().toString());
                } else {
                    return;
                }
            } else if (parametersToModify?.aggregateBy) {
                // eslint-disable-next-line default-case
                filterHistory.push(JSON.parse(JSON.stringify(filters)));
                dispatch(setFilterUndoCount(filterHistory.length));
                const value = parametersToModify.aggregateBy.value;
                switch (parametersToModify.aggregateBy.name) {
                    case "source":
                        setFilters((prev) => ({...prev, source: value}));
                        break;
                    case "main_topics":
                        setFilters((prev) => ({...prev, main_topics: value}));
                        break;
                    case "subjects":
                        setFilters((prev) => ({...prev, subjects: value}));
                        break;
                    case "entities":
                        setFilters((prev) => ({...prev, entities: value}));
                        break;
                    case "authors":
                        setFilters((prev) => ({...prev, authors: value}));
                        break;
                    case "locations":
                        setFilters((prev) => ({...prev, locations: value}));
                        break;
                    case "author_handle":
                        setFilters((prev) => ({...prev, author_handle: value}));
                        break;
                    case "document_date":
                        setFilters((prev) => ({
                            ...prev,
                            publish_datetime_from: value[0],
                            publish_datetime_until: value[1]
                        }));
                        break;
                    case "index_date":
                        setFilters((prev) => ({
                            ...prev,
                            index_datetime_from: value[0],
                            index_datetime_until: value[1]
                        }));
                        break;
                    case "sentiment":
                        const sentiment = {}
                        for (let key in filters.sentiment) {
                            sentiment[key] = key === value;
                        }
                        setFilters((prev) => ({...prev, sentiment: sentiment}));
                        break;

                    default:
                        return;
                }
                setTriggerSearch(new Date().toString());

            } else if (parametersToModify?.likeThis) {
                const doc = await getDocumentDetail(user?.token, parametersToModify?.likeThis.guid)
                const content = doc.meta?.summary || doc.content;
                console.log( 'query', content);
                setQuery(content);
                setTriggerSearch(new Date().toString());

            } else if (parametersToModify.backHistory) {
                selectHistory(queryHistory.pop());
            } else if (parametersToModify.resetHistory) {
                selectHistory(queryHistory.pop_to_first());
            }
        }

        apply(parametersToModify);
    }, [parametersToModify])

    const loadSavedSearch = async () => {
        const searchList = await getSavedSearchList(user.token);
        setSavedSearchList(searchList)
    };

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const onSearch = async (params = {}, from=0, size=12, nohistory=false) => {
        const _filters = {...filters}
        _filters['sentiment'] = Object.entries(_filters['sentiment'])
            .filter(([key, value]) => value === true)
            .map(([key]) => key);


        const useSortBy = (!!query)? "relevance": sortBy;

        try {
            dispatch(setLoading(true));
            // const results = await searchDocuments({query, ...advancedParams}, user.token);
            const results = await searchDocuments4(user.token, query, keywords,
                isUnion? "union": "and",
                isExactKeyword,
                url, title, _filters,
                useSortBy, aggregateBy, from, size, nohistory);
            dispatch(setSearchResults({
                filters: _filters,
                query,
                keywords,
                url,
                title,
                isUnion,
                isExactKeyword,
                sortBy: useSortBy,
                aggregateBy,
                from,
                size,
                results
            }));
            if (useSortBy!== sortBy) {
                setSortBy(useSortBy);
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading( false));
        }
    };

    const handleInputKeyDown = async (event) => {
        if (event.ctrlKey && event.key === 'Enter') {
            // Prevent the default action to avoid any unwanted behavior
            event.preventDefault();
            await onSearch();
        }
    }


    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((filters) => ({ ...filters, [name]: value }));
    };

    const handleSentimentChange = (e) => {
        const { name, checked } = e.target;
        setFilters({
            ...filters,
            sentiment: {
                ...filters.sentiment,
                [name]: checked
            }
        });
    };


    const filterOptions = (
        <div className={styles['filters-container']}>
            {/* fonte */}
            <div className={styles['input-group']}>
                <SubSectionTitle>Fonte da notícia</SubSectionTitle>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>ae-broadcast g1.globo</span>
                </div>
                <input
                    type="text"
                    name="source"
                    onKeyDown={handleInputKeyDown}
                    value={filters.source}
                    onChange={handleFilterChange}
                />
            </div>
            <div className={styles['input-group']}>
                <SubSectionTitle>Author handle (social)</SubSectionTitle>
                <input
                    type="text"
                    name="author_handle"
                    onKeyDown={handleInputKeyDown}
                    value={filters.author_handle}
                    onChange={handleFilterChange}
                />
            </div>
            {/* data do documento (de - até)*/}
            <div className={styles['input-group']}>
                <SubSectionTitle>Data de publicação da notícia</SubSectionTitle>
                <div className={styles["filter-description"]}>Selecione a data de publicação (de - até)</div>
                {/*<div className={styles['date-picker-group']}>*/}
                <div>
                    <label>De: </label>
                    <input
                        type="date"
                        name="publish_datetime_from"
                        value={filters.publish_datetime_from}
                        onChange={handleFilterChange}
                        placeholder="YYYY-MM-DD"
                    />
                </div>
                <div>
                    <label>Até: </label>
                    <input
                        type="date"
                        name="publish_datetime_until"
                        value={filters.publish_datetime_until}
                        onChange={handleFilterChange}
                        placeholder="YYYY-MM-DD"
                        min={filters.publish_datetime_from} // Ensure 'until' date is not before 'from' date
                    />
                </div>
            </div>
            {/*</div>*/}


            {/* data do indice (de - até)*/}
            <div className={styles['input-group']}>
                <SubSectionTitle>Data importada na base</SubSectionTitle>
                <div className={styles["filter-description"]}>Selecione a data de importação (de - até)</div>
                {/*<div className={styles['date-picker-group']}>*/}
                <div>
                    <label>De: </label>
                    <input
                        type="date"
                        name="index_datetime_from"
                        value={filters.index_datetime_from}
                        onChange={handleFilterChange}
                        placeholder="YYYY-MM-DD"
                    />
                </div>
                <div>
                    <label>Até: </label>
                    <input
                        type="date"
                        name="index_datetime_until"
                        value={filters.index_datetime_until}
                        onChange={handleFilterChange}
                        placeholder="YYYY-MM-DD"
                        min={filters.index_datetime_from} // Ensure 'until' date is not before 'from' date
                    />
                </div>
            </div>

            {/* sentimento */}
            <div className={styles['input-group']}>
                <SubSectionTitle>Sentimento</SubSectionTitle>
                <div className={styles["filter-description"]}>Selecione um ou mais sentimentos</div>
                <div className={styles['checkbox-group']}>
                    <label>
                        <input
                            type="checkbox"
                            name="neutral"
                            checked={filters.sentiment.neutral}
                            onChange={handleSentimentChange}
                        />
                        Neutro
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="positive"
                            checked={filters.sentiment.positive}
                            onChange={handleSentimentChange}
                        />
                        Positivo
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="negative"
                            checked={filters.sentiment.negative}
                            onChange={handleSentimentChange}
                        />
                        Negativo
                    </label>
                </div>
            </div>

            {/* temas principais */}
            <div className={styles['input-group']}>
                <SubSectionTitle>Temas principais</SubSectionTitle>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>"Mercado de cacau"</span>
                </div>
                <input
                    type="text"
                    name="main_topics"
                    onKeyDown={handleInputKeyDown}
                    value={filters.main_topics}
                    onChange={handleFilterChange}
                />
            </div>

            {/* assuntos */}
            <div className={styles['input-group']}>
                <SubSectionTitle>Assuntos</SubSectionTitle>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>Investimentos "Acões de empresas"</span>
                </div>
                <input
                    type="text"
                    name="subjects"
                    onKeyDown={handleInputKeyDown}
                    value={filters.subjects}
                    onChange={handleFilterChange}
                />
            </div>
            {/* entities */}
            <div className={styles['input-group']}>
                <SubSectionTitle>Entes (pessoa ou empresa)</SubSectionTitle>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>Technobrás "ACME Corp" "José Silva"</span>
                </div>
                <input
                    type="text"
                    name="entities"
                    onKeyDown={handleInputKeyDown}
                    value={filters.entities}
                    onChange={handleFilterChange}
                />
            </div>
            {/* autores */}
            <div className={styles['input-group']}>
                <SubSectionTitle>Autores</SubSectionTitle>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>"Jorge Amado"</span>
                </div>
                <input
                    type="text"
                    name="authors"
                    onKeyDown={handleInputKeyDown}
                    value={filters.authors}
                    onChange={handleFilterChange}
                />
            </div>
            {/* localidade */}
            <div className={styles['input-group']}>
                <SubSectionTitle>Localidade</SubSectionTitle>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>"Mato grosso"</span>
                </div>
                <input
                    type="text"
                    name="locations"
                    onKeyDown={handleInputKeyDown}
                    value={filters.locations}
                    onChange={handleFilterChange}
                />
            </div>
        </div>
    )

    const [radarName, setRadarName] = useState(null);
    const [selectedSavedSearch, setSelectedSavedSearch] = useState('');


    const selectSavedSearch = (id) => {
        const saved = savedSearchList.find(item => item.id === id);
        console.log('selected: ', saved)
        setKeywords(saved?.keywords);
        setUrl(saved?.url);
        setTitle(saved?.title);
        setQuery(saved?.q);
        setSortBy(saved?.sort_by);
        setAggregateBy(saved?.aggregate_by);
        setIsUnion(saved?.combine_mode === "union");
        setFilters({
            source: saved.filters?.source,
            fromDate: saved.filters?.fromDate,
            untilDate: saved.filters?.untilDate,
            publish_datetime_from: saved.filters?.publish_datetime_from,
            publish_datetime_until: saved.filters?.publish_datetime_until,
            index_datetime_from: saved.filters?.index_datetime_from,
            index_datetime_until: saved.filters?.index_datetime_until,
            sentiment: {
                neutral: (saved.filters?.sentiment || []).includes('neutral'),
                positive: (saved.filters?.sentiment || []).includes('positive'),
                negative: (saved.filters?.sentiment || []).includes('negative'),
            },
            main_topics: saved.filters?.main_topics,
            subjects: saved.filters?.subjects,
            entities: saved.filters?.entities,
            authors: saved.filters?.authors,
            author_handle: saved.filters?.author_handle,
            locations: saved.filters?.locations,
        });

        setSelectedSavedSearch(id);
    }

    const selectHistory = (h) => {
        if (!h) return;
        setQuery(h.semantic_query);
        if (h.keyword_exact_query) {
            setExactKeyword(true);
            setKeywords(h.keyword_exact_query);
        } else {
            setExactKeyword(false);
            setKeywords(h.keyword_fuzzy_query);
        }
        setUrl( h.url);
        setTitle(h.title);
        setFilters(JSON.parse(JSON.stringify(h.filters)));
        setSortBy(h.sortBy);
        setAggregateBy(h.aggregate_by);

        filters.sentiment= {
            neutral: (h.filters?.sentiment || []).includes('neutral'),
            positive: (h.filters?.sentiment || []).includes('positive'),
            negative: (h.filters?.sentiment || []).includes('negative'),
        };

        setTimeout(()=> {
            setTriggerSearch("nohistory-"+ new Date());
        }, 100);
    }

    const divSavedSearchModal = !!showSaved && (
        <MaxModal
            // clickPosition ={showSaved}
            handleClose={() => setShowSaved(null)} closeLabel={"Close"}
            // nonblocking = {true}
        >
            <div className={styles['modal-list']}>
                {savedSearchList.map((item) => (
                    <div key={item.id}
                         className={styles["modal-list-items"]}
                         onClick = {()=> {
                             selectSavedSearch(item.id);
                             setShowSaved(null);
                         }}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </MaxModal>
    )

    const divHistoryModal_max = !!showHistory && (
        <MaxModal
            // clickPosition ={showHistory}
            handleClose={() => setShowHistory(null)} closeLabel={"Close"}
            // nonblocking = {true}
        >
            <div className={styles['modal-list']}>
                {queryHistory.getList().map((item) => (
                    <div key={item.id}
                         className={styles["modal-list-items"]}
                         onClick = {()=> {
                             selectHistory(item);
                             setShowHistory(null);
                         }}
                    >
                        <ReactJson src={removeNullKeys(item)} theme={"twilight"}/>
                    </div>
                ))}
            </div>
        </MaxModal>
    )

    const divHistoryModal = !!showHistory && (
        <ContextModal
            // clickPosition ={showHistory}
            handleClose={() => setShowHistory(null)} closeLabel={"Close"}
            // nonblocking = {true}
        >
            <div className={styles['modal-list']}>
                <h2>History list</h2>
                {queryHistory.getList().map((item) => (
                    <div key={item.id}
                         className={styles["modal-list-items"]}
                         onClick = {()=> {
                             selectHistory(item);
                             setShowHistory(null);
                         }}
                    >
                        <ReactJson src={removeNullKeys(item)} theme={"twilight"}
                                   enableClipboard={false}
                                   displayDataTypes={false}
                                   displayObjectSize={false}
                        />
                    </div>
                ))}
            </div>
        </ContextModal>
    )


    const divHelpModal = !!showHelp && (
        <ContextModal
            nonblocking = {true}
            clickPosition ={showHelp[0]}
            handleClose={() => setShowHelp(null)} closeLabel={"Close"}
            // nonblocking = {true}
        >
            <div className={styles['help-content']}>
                {showHelp[1]}
            </div>
        </ContextModal>
    )

    const searchTop_simple = (
        <div className={styles["top-bar--simple"]}>
            <div className={styles["keyword-input--simple"]}>
                <input
                    type="text"
                    // placeholder="Enter your query..."
                    onChange={(e) => setKeywords(e.target.value)}
                    onKeyDown={handleInputKeyDown}
                    value={keywords}
                    // onKeyDown={handleSearch}
                />
                {keywords && <div className={`fa-icon ${styles["clear-icon"]}`}
                               onClick={()=> setKeywords('')}
                >
                    <MdOutlineClear/>
                </div>}

            </div>
            <div className={styles["search-box-right"]}
            >
                <button type="button" className={styles["circle-button"]}
                        onClick={() => onSearch()}
                        title='use também CTRL+ENTER nas campos de input'
                >
                    <FaArrowRight size={24}/>
                </button>
            </div>
        </div>
    )


    const searchTop = (
        <div className={styles["top-bar"]}>
            {/*<h2>Busque por notícias...</h2>*/}
            <div className={styles["keyword-bar"]}>
                <div className={styles["title-with-icon"]}>
                    <SectionTitle>
                        Busca por keyword
                    </SectionTitle>
                    <div className={`${styles["info-icon"]}`}
                         onClick={(e) => {
                             if (!!showHelp)
                                 setShowHelp(null);
                             else {
                                 const rect = e.target.getBoundingClientRect();
                                 setShowHelp([{top: rect.top, left: rect.left + 100}, help_for_keyword]);
                             }
                         }}>
                        <div className={`fa-icon -smaller -pinkish`}>
                            <FaCircleInfo/>
                        </div>
                        dicas de busca
                    </div>
                </div>
                <div className={styles["keyword-input"]}>
                    <input
                        type="text"
                        // placeholder="Enter your query..."
                        onChange={(e) => setKeywords(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        value={keywords}
                        // onKeyDown={handleSearch}
                    />
                    <label>
                        <input
                            type="checkbox"
                            checked={isExactKeyword}
                            onChange={(e) => setExactKeyword(e.target.checked)}
                        />
                        Exact Match
                    </label>
                </div>
                <div className={styles["url-input"]}>
                    URL:
                    <input
                        type="text"
                        onChange={(e) => setUrl(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        value={url}
                    />
                </div>
                <div className={styles["title-input"]}>
                    Title:
                    <input
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        value={title}
                    />
                </div>
                <div className={styles["instructions"]}>URL e Title são buscas abertas, ie, o filtro não é aplicado.</div>
                {/*<FaSearch className={styles["search-icon"]}/>*/}
            </div>
            <div className={styles["choice-bar"]}>
                <div className={styles["toggle-container"]}>
                    <label>
                        <input
                            type="radio"
                            name="choice"
                            value="UNION"
                            checked={isUnion}
                            onChange={()=> setIsUnion(true)}
                        />
                        UNION
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="choice"
                            value="AND"
                            checked={!isUnion}
                            onChange={()=> setIsUnion(false)}
                        />
                        AND
                    </label>
                </div>
            </div>

            <div className={styles["text-bar"]}>
                <SectionTitle>
                    Busca semântica
                </SectionTitle>
                <div className={styles["semantic-input"]}>
                    <textarea
                        // type="text"
                        placeholder="Enter your query..."
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        value={query}
                        // onKeyDown={handleSearch}
                    />
                    {query && <div className={`fa-icon ${styles["clear-icon"]}`}
                            onClick={()=> setQuery('')}
                        >
                        <MdOutlineClear/>
                    </div>}
                </div>
            </div>
            <div className={styles["search-box-right"]}
            >
                <button type="button" className={styles["circle-button"]}
                        onClick={() => onSearch()}
                        title='use também CTRL+ENTER nas campos de input'
                >
                    <FaArrowRight size={24}/>
                </button>
            </div>


        </div>
    );

    const [showFilter, setShowFilter] = useState(false);


    const searchTopNav = (
        <div className={styles["nav-container"]}>
            <div className={styles["command-icons"]}>
                <div className={"fa-icon -smaller"}
                     onClick={() => setSimpleSearch(!simpleSearch)}
                >
                    {simpleSearch ? <FaChevronDown/> : <FaChevronUp/>}
                </div>
            </div>
            <div className={`${styles["command-icons"]} ${styles["history-icon"]}`}>
                <div className={"fa-icon -smaller"}
                     onClick={(e) => {
                         const rect = e.target.getBoundingClientRect();
                         setShowHistory({top: rect.top, left: rect.left});
                     }}>
                    <FaClock/>
                </div>
                <div className={styles["history-size"]}>
                    {queryHistory.size()}
                </div>
            </div>
            <div className={styles["command-icons"]}>
                <div className={"fa-icon -smaller"}
                     onClick={(e) => {
                         const rect = e.target.getBoundingClientRect();
                         setShowSaved({top: rect.top, left: rect.left});
                     }}>
                    <FaHistory/>
                </div>
            </div>
            <div className={styles["command-icons"]}>
                <div className={"fa-icon -smaller"}
                     onClick={() => setShowFilter(!showFilter)}
                >
                    <FaSliders/>
                </div>
            </div>
        </div>
    )


    const searchFilter = (
        <div className={`${styles["filter-bar"]} ${showFilter ? '' : styles["filter-bar-hide"]}`}>
            <div className={styles["filter-content"]}>
                <div className={styles['info']}>
                    {indexes && <>
                        <div className={styles['info-row']}>
                            <div className={styles['info-label']}>Index name:</div>
                            <div className={styles['info-value']}>{indexes[INDEX_NAME]["index"]}</div>
                        </div>
                        <div className={styles['info-row']}>
                            <div className={styles['info-label']}>Number of documents:</div>
                            <div className={styles['info-value']}>{indexes[INDEX_NAME]["docs.count"]}</div>
                        </div>
                        <div className={styles['info-row']}>
                            <div className={styles['info-label']}>Store size:</div>
                            <div className={styles['info-value']}>{indexes[INDEX_NAME]["store.size"]}</div>
                        </div>
                    </>}
                </div>
                <div className={styles["search-box"]}>
                    <div className={styles["search-box-left"]}>
                        <SectionTitle>Agregado por...</SectionTitle>
                        <div className={styles["select-div"]}>
                            <select
                                name="aggregate_by"
                                value={aggregateBy}
                                onChange={(e) => setAggregateBy(e.target.value)}
                            >
                                {aggOptions.map((opt) => (
                                    <option key={opt.code} value={opt.code}>
                                        {opt.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <SectionTitle>Ordenado por...</SectionTitle>
                        <div className={styles["select-div"]}>
                            <select
                                name="sortBy"
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                            >
                                {sortOptions.map((opt) => (
                                    <option key={opt.code} value={opt.code}>
                                        {opt.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <SectionTitle>Filtrados por...</SectionTitle>
                        <div className={styles["instructions"]}>
                            filtro não interfere no score<br/>
                            A Api vai aceitar estes valores como lista embora aqui seja passado como uma string.<br/>
                            O filtro permite duplas aspas para palavras compostas e NOT <br/>
                            Exemplos de uso:<br/><ul className={"simple-ul"}>
                            <li>NOT "Pedro da Silva" "João Pessoa"</li>
                                <li>Jocimara "Maria de Andrade"</li>
                                    <li>NOT Jocimara</li></ul>
                        </div>


                        {filterOptions}
                    </div>
                </div>
            </div>
        </div>

    )

    return (
        <div className={styles["search-top"]}>
            {simpleSearch? (
                <div className={styles["search-bar--simple"]}>
                    {searchTopNav}
                    {searchTop_simple}
                </div>
            ): (
                <div className={styles["search-bar"]}>
                    {searchTopNav}
                    {searchTop}
                </div>
            )}
            <div className={styles["search-content"]}>
                {searchFilter}
                <SearchViewer/>
                {divSavedSearchModal}
            </div>
            {divHelpModal}
            {divHistoryModal}
        </div>
    )


};

export default SearchPanel;
