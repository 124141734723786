import styles from "./MonitorTop.module.css";
import React, {useEffect, useState} from "react";
import {Link, Route, Routes} from "react-router-dom";
import {MdMonitor, MdOutlineDraw, MdQueryStats, MdQueue} from "react-icons/md";
import {FaHistory, FaSearch, FaTasks} from "react-icons/fa";
import MonitorQueue from "../MonitorQueue/MonitorQueue";
import MonitorLog from "../MonitorLog/MonitorLog";
import MonitorAugBatch from "../MonitorAugBatch/MonitorAugBatch";
import {AiOutlineFileText} from "react-icons/ai";
import {BiSolidCoinStack} from "react-icons/bi";


const MonitorTop = () => {
    return (
        <div className={styles["nav-container"]}>
            <div className={styles['monitor-container-left']}>
                <div className={styles["command-icons"]}>
                    <Link to="queue" className={"fa-icon"}
                          title={"queues"}>
                        <BiSolidCoinStack />
                    </Link>
                    <Link to="logs" className={"fa-icon"}
                          title={"logs"}>
                        <AiOutlineFileText/>
                    </Link>
                    <Link to="aug-batch" className={"fa-icon"}
                          title={"augmentation batch"}>
                        <FaTasks/>
                    </Link>
                </div>
            </div>
            <div className={styles['monitor-container-right']}>
                <Routes>
                    <Route path="queue" element={<MonitorQueue/>}/>
                    <Route path="logs" element={<MonitorLog/>}/>
                    <Route path="aug-batch" element={<MonitorAugBatch/>}/>
                </Routes>
            </div>
        </div>
    )
}

export default MonitorTop;
