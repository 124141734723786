import styles from './MonitorAugBatch.module.css'
import React, {useEffect, useRef, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {setLoading, useGlobalDispatch, useGlobalState} from "../../context/GlobalState";
import {getAugBatch, getAugBatchErrorLog, getQueueCount} from "../../services/api";
import ReactJson from "react-json-view";
import MaxModal from "../MaxModal/MaxModal";
import {TfiReload} from "react-icons/tfi";

function simplifyDateFormat(input) {
    if (!input) return input;
    if (typeof input !== 'string') {
        return input;
    }
    const [date, timeWithMilliseconds] = input.split('T');
    const [time] = timeWithMilliseconds.split('.');
    return `${date} ${time}`;
}

function simplifyDateFormat2(input) {
    if (!input) return input;
    if (typeof input !== 'string') {
        return input;
    }
    const [date, timeWithMilliseconds] = input.split('T');
    const [year, month, day] = date.split('-');
    const [time] = timeWithMilliseconds.split('.');
    const [hour, minute] = time.split(':');

    return `${month}/${day} ${hour}:${minute}`;
}
function simplifyDateFormat3(input) {
    if (!input) return input;
    if (typeof input !== 'string') {
        return input;
    }
    const [date, timeWithMilliseconds] = input.split('T');
    const [year, month, day] = date.split('-');
    const [time] = timeWithMilliseconds.split('.');
    const [hour, minute] = time.split(':');

    return `${hour}:${minute}`;
}

const statusList = [
    {code: "", name: "all"},
    {code: "success", name: "success"},
    {code: "error", name: "error"},
    {code: "processing", name: "processing"},
]


const MonitorAugBatch = () => {
    const dispatch = useGlobalDispatch();
    const [queueCount, setQueueCount] = useState([]);
    const { user } = useAuth();
    const [fetchError, setFetchError] = useState(null); // State to track fetch errors
    const [augBatchList, setAugBatchList] = useState([]);
    const hasFetchedData = useRef(false); // Ref to track if data has been fetched
    const [detail, setDetail] = useState(null);
    const { isMobile } = useGlobalState();
    const [status, setStatus] = useState('');

    useEffect(() => {
        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true; // Mark as fetched
        }
    }, []);
    useEffect(() => {
        fetchData();
    }, [status]);

    const fetchData = async () => {
        try {
            dispatch(setLoading(true));
            const result = await getAugBatch(user.token, status)
            setAugBatchList( result);
        } catch (error) {
            console.error("Error fetching data:", error);
            setFetchError("Failed to fetch data. Please try again later.");
        } finally {
            dispatch(setLoading(false));
        }
    }

    const loadErrorLog = async (batch_id) => {
        try {
            dispatch(setLoading(true));
            const result = await getAugBatchErrorLog(user.token, batch_id)
            setDetail( result);
        } catch (error) {
            console.error("Error fetching data:", error);
            setFetchError("Failed to fetch data. Please try again later.");
        } finally {
            dispatch(setLoading(false));
        }

    }

    const detailModal = detail &&  (
        <MaxModal
            handleClose={() => setDetail(null)}
        >
            <div className={styles["detail-container"]}>
                <ReactJson src={detail} theme={"twilight"}/>
            </div>
        </MaxModal>
    )

    const listDiv = isMobile? (
        <div className={styles["data-container"]}>
            {augBatchList && (
                <div className={`${styles["item-row"]} ${styles["title"]}`}>
                    <div>created at</div>
                    <div>type</div>
                    <div>status</div>
                    <div className={styles["num-request"]}># req</div>
                    <div>last checked at</div>
                    <div>result retrieved at</div>
                    <div>OK</div>
                    <div>NG</div>
                </div>
            )}
            {augBatchList.map((item, index) => (
                <div className={styles["item-row"]}

                >
                    <div onClick={() => setDetail(item)}
                         className={styles["item-selectable"]}
                    >
                        {simplifyDateFormat2(item.created_at)}</div>
                    <div>{item.type}</div>
                    <div className={styles[`status-${item.status}`]}>{item.status}</div>
                    <div className={styles["num-request"]}>{item.number_of_requests}</div>
                    <div>{simplifyDateFormat2(item.last_checked_at)}</div>
                    <div>{simplifyDateFormat3(item.result_retrieved_at)}</div>
                    <div className={styles["number"]}>{item?.received_status?.request_counts?.completed || "-"}</div>
                    <div className={`${styles["error"]} ${styles["number"]}`}
                         onClick={() => loadErrorLog(item?._id)}
                    >{item?.received_status?.request_counts?.failed || "-"}</div>
                </div>
            ))}
        </div>
    ) : (
        <div className={styles["data-container"]}>
            {augBatchList && (
                <div className={`${styles["item-row"]} ${styles["title"]}`}>
                <div>ID</div>
                    <div>created at</div>
                    <div>type</div>
                    <div>status</div>
                    <div># req</div>
                    <div>last checked at</div>
                    <div>result retrieved at</div>
                    <div>OK</div>
                    <div>NG</div>
                </div>
            )}
            {augBatchList.map((item, index) => (
                <div className={styles["item-row"]}

                >
                    <div onClick={() => setDetail(item)} className={styles["item-selectable"]}>{item._id}</div>
                    <div>{simplifyDateFormat2(item.created_at)}</div>
                    <div>{item.type}</div>
                    <div className={styles[`status-${item.status}`]}>{item.status}</div>
                    <div className={styles["num-request"]}>{item.number_of_requests}</div>
                    <div>{simplifyDateFormat2(item.last_checked_at)}</div>
                    <div>{simplifyDateFormat3(item.result_retrieved_at)}</div>
                    <div className={styles["number"]}>{item?.received_status?.request_counts?.completed || "-"}</div>
                    <div className={`${styles["error"]} ${styles["number"]}`}
                         onClick={() => loadErrorLog(item?._id)}
                    >{item?.received_status?.request_counts?.failed || "-"}</div>
                </div>
            ))}
        </div>
    )


    return (
        <div className={styles["container"]}>
            {fetchError && <div className={styles["error-message"]}>{fetchError}</div>}
            <h2>Augmentation Batch List</h2>
            <div className={styles["icons-panel"]}>
                <div className={"fa-icon -smaller-x"}
                     onClick={(e) => {
                         fetchData()
                     }}>
                    <TfiReload/>
                </div>
            </div>
            <div className={styles["select-div"]}>
                <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    {statusList.map((value) => (
                        <option key={value.code} value={value.code}>
                            {value.name}
                        </option>
                    ))}
                </select>
            </div>

            {listDiv}
            {detailModal}
        </div>
    )
}

export default MonitorAugBatch;
