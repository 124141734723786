import styles from './MonitorLog.module.css'
import React, {useEffect, useRef, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {
    getActivityLogForSource,
    getErrorLog,
    getErrorLogForSource,
    getLogCounts,
    getLogCountsBySource, getLogServices
} from "../../services/api"
import {setLoading, useGlobalDispatch} from "../../context/GlobalState";
import ReactJson from "react-json-view";
import MaxModal from "../MaxModal/MaxModal";
import {TfiReload} from "react-icons/tfi";

const keys = ["url-frontier-g1", "web-scrap-scheduler", "web-scrap-receiver", "broadcast-receiver", "content-processor", "indexer", "alert-monitor"]

const WEB_SCRAP_RECEIVER = "web-scrap-receiver";
const CONTENT_PROCESSOR = "content-processor";


const MonitorLog = () => {
    const dispatch = useGlobalDispatch();
    const [logCount, setLogCount] = useState(null);
    const { user } = useAuth();
    const [ errorList, setErrorList] = useState([]);
    const [fetchError, setFetchError] = useState(null); // State to track fetch errors
    const hasFetchedData = useRef(false); // Ref to track if data has been fetched
    const [service, setService] = useState(null);
    const [logCountBySource, setLogCountBySource] = useState(null);
    const [serviceList, setServiceList] = useState([])
    const [countInWebscrapReceiver, setCountInWebscrapReceiver] = useState(null);
    const [countInContentProcessor, setCountInContentProcessor] = useState(null);


    useEffect(() => {
        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true; // Mark as fetched
        }
    }, []);

    useEffect(()=> {
        if (user?.token && service) {
            getLogCountsBySource(user.token, service).then(result => setLogCountBySource(result));
        }
    }, [service]);


    const fetchData = async () => {
        try {
            dispatch(setLoading(true));

            const result = await getLogCounts(user.token)
            setLogCount( result);

            const serviceList = await getLogServices(user.token)
            setServiceList( serviceList);

            getLogCountsBySource(user.token, WEB_SCRAP_RECEIVER).then(result => setCountInWebscrapReceiver(result));
            getLogCountsBySource(user.token, CONTENT_PROCESSOR).then(result => setCountInContentProcessor(result));
            // await getLogCountsBySource(user.token, URL_FRONTIER).then(result => setCountInUrlFrontier(result));

        } catch (error) {
            console.error("Error fetching data:", error);
            setFetchError("Failed to fetch data. Please try again later.");
        } finally {
            dispatch(setLoading(false));
        }
    }

    const reloadLogCount = async () => {
        try {
            dispatch(setLoading(true));
            const result = await getLogCounts(user.token)
            setLogCount( result);
        } finally {
            dispatch(setLoading(false));
        }
    }
    const reloadWebscrapReceiver = async () => {
        try {
            dispatch(setLoading(true));
            await getLogCountsBySource(user.token, WEB_SCRAP_RECEIVER).then(result => setCountInWebscrapReceiver(result));
        } finally {
            dispatch(setLoading(false));
        }
    }
    const reloadContentProcessor = async () => {
        try {
            dispatch(setLoading(true));
            await getLogCountsBySource(user.token, CONTENT_PROCESSOR).then(result => setCountInContentProcessor(result));
        } finally {
            dispatch(setLoading(false));
        }
    }

    async function loadErrors(date, k) {
        try {
            dispatch(setLoading(true));
            const result = await getErrorLog(user.token, date, k);
            setErrorList(result);
        } catch (error) {
            console.error("Error loading errors:", error);
        } finally {
            dispatch(setLoading(false));
        }
    }

    async function loadErrorsForSource(date, _service, source) {
        try {
            dispatch(setLoading(true));
            const result = await getErrorLogForSource(user.token, date, _service, source);
            setErrorList(result);
        } catch (error) {
            console.error("Error loading errors:", error);
        } finally {
            dispatch(setLoading(false));
        }
    }

    async function loadActivityForSource(date, _service, source) {
        try {
            dispatch(setLoading(true));
            const result = await getActivityLogForSource(user.token, date, _service, source);
            setErrorList(result);
        } catch (error) {
            console.error("Error loading errors:", error);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const errorDetailModal = (errorList && errorList.length> 0 && (
        <MaxModal
            handleClose={()=> setErrorList(null)}
        >
            <div className={styles["error-list-container"]}>
                {errorList.map((_item, index) => (<div>
                    <ReactJson src={_item} theme={"twilight"}/>
                </div>))}
            </div>
        </MaxModal>
    ))

    const zeroAsNull = (d) => {
        return d===0?'-':d
    }

    const divLogCount = logCount && (<>
            <h2>Log Counts</h2>
            <div className={styles["icons-panel"]}>
                <div className={"fa-icon -smaller-x"}
                     onClick={(e) => {
                         reloadLogCount()
                     }}>
                    <TfiReload/>
                </div>
            </div>
            <div className={styles["list-container"]}>
                <div className={styles["item-row"]}>
                    <div className={styles["item-title"]}>Service</div>
                    {logCount.dates.map((k) => (<div className={styles["item-block-title"]}>
                        {k.split('-').pop()}
                    </div>))}
                </div>
                {logCount.count.map((_item, index) => (<div className={styles["item-row"]}>
                    <div className={styles["item-title"]}>
                        {_item.service}
                    </div>
                    {logCount.dates.map((k) => (<div className={styles["item-block"]}>
                        <div className={styles["activity"]}
                             onClick={() => loadActivityForSource(k, _item.service)}
                        >
                            {(_item.value[k] || {})["activity"]}
                        </div>
                        <div className={styles["error"]}
                             onClick={() => loadErrors(k, _item.service)}
                        >
                            {zeroAsNull((_item.value[k] || {})["error"])}
                        </div>
                    </div>))}
                </div>))}
            </div>
    </>)


    const divCountInWebScrapReceiver = countInWebscrapReceiver && (<>
        <h2>Log Counts by Source - <b>webscrap-receiver</b></h2>
        <div className={styles["icons-panel"]}>
            <div className={"fa-icon -smaller-x"}
                 onClick={(e) => {
                         reloadWebscrapReceiver()
                     }}>
                    <TfiReload/>
                </div>
            </div>

            <div className={styles["list-container"]}>
                <div className={styles["item-row"]}>
                    <div className={styles["item-title"]}>Source</div>
                    {countInWebscrapReceiver.dates.map((k) => (
                        <div className={styles["item-block-title"]}>
                            {k.split('-').pop()}
                        </div>))}
                </div>
                {countInWebscrapReceiver.count.map((_item, index) => (
                    <div className={styles["item-row"]}>
                        <div className={styles["item-title"]}>
                            {_item.source}
                        </div>
                        {countInWebscrapReceiver.dates.map((k) => (
                            <div className={styles["item-block"]}>
                                <div className={styles["activity"]}
                                     onClick={() => loadActivityForSource(k, WEB_SCRAP_RECEIVER, _item.source)}
                                >
                                    {(_item.value[k] || {})["activity"]}
                                </div>
                                <div className={styles["error"]}
                                     onClick={() => loadErrorsForSource(k, WEB_SCRAP_RECEIVER, _item.source)}
                                >
                                    {zeroAsNull((_item.value[k] || {})["error"])}
                                </div>
                            </div>))}
                    </div>))}
            </div>
        </>)


    const divCountInContentProfcessor = countInContentProcessor && (<>
            <h2>Log Counts by Source - <b>content-processor</b></h2>
            <div className={styles["icons-panel"]}>
                <div className={"fa-icon -smaller-x"}
                     onClick={(e) => {
                         reloadContentProcessor()
                     }}>
                    <TfiReload/>
                </div>
            </div>

            <div className={styles["list-container"]}>
                <div className={styles["item-row"]}>
                    <div className={styles["item-title"]}>Source</div>
                    {countInContentProcessor.dates.map((k) => (
                        <div className={styles["item-block-title"]}>
                            {k.split('-').pop()}
                        </div>))}
                </div>
                {countInContentProcessor.count.map((_item, index) => (
                    <div className={styles["item-row"]}>
                        <div className={styles["item-title"]}>
                            {_item.source}
                        </div>
                        {countInContentProcessor.dates.map((k) => (
                            <div className={styles["item-block"]}>
                                <div className={styles["activity"]}
                                     onClick={() => loadErrorsForSource(k, CONTENT_PROCESSOR, _item.source)}
                                >
                                    {(_item.value[k] || {})["activity"]}
                                </div>
                                <div className={styles["error"]}
                                     onClick={() => loadErrorsForSource(k, CONTENT_PROCESSOR, _item.source)}
                                >
                                {zeroAsNull((_item.value[k] || {})["error"])}
                                </div>
                            </div>))}
                    </div>))}
            </div>
        </>
    )

    return (
        <div className={styles["container"]}>
            <div className={styles["data-container"]}>
                {fetchError && <div className={styles["error-message"]}>{fetchError}</div>}
                {divLogCount}
                {divCountInWebScrapReceiver}
                {divCountInContentProfcessor}

                {countInContentProcessor && (<>
                    <h2>Log Counts by Source</h2>
                    <div className={styles["select-div"]}>
                        <select
                            name="aggregate_by"
                            value={service}
                            onChange={(e) => setService(e.target.value)}
                        >
                            {serviceList.map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                </>)}

                {logCountBySource && (
                    <div className={styles["list-container"]}>
                        <div className={styles["item-row"]}>
                            <div className={styles["item-title"]}>Source</div>
                            {logCountBySource.dates.map((k) => (<div className={styles["item-block-title"]}>
                                {k.split('-').pop()}
                            </div>))}
                        </div>
                        {logCountBySource.count.map((_item, index) => (<div className={styles["item-row"]}>
                            <div className={styles["item-title"]}>
                                {_item.source}
                            </div>
                            {logCountBySource.dates.map((k) => (<div className={styles["item-block"]}>
                                <div className={styles["activity"]}
                                     onClick={() => loadActivityForSource(k, service, _item.source)}
                                >
                                    {(_item.value[k] || {})["activity"]}
                                </div>
                                <div className={styles["error"]}
                                     onClick={() => loadErrorsForSource(k, service, _item.source)}
                                >
                                    {zeroAsNull((_item.value[k] || {})["error"])}
                                </div>
                            </div>))}
                        </div>))}
                    </div>)}

                </div>
                {errorDetailModal}
            </div>
            )
            }

export default MonitorLog;
