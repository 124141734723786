class QueryHistory {
    constructor() {
        if (!QueryHistory.instance) {
            this.history = [];
            this.historySet = new Set();
            QueryHistory.instance = this;
        }
        return QueryHistory.instance;
    }

    add(entry) {
        if (!this.historySet.has(JSON.stringify(entry))) {
            this.history.push(entry);
            this.historySet.add(JSON.stringify(entry));

            console.log(this.history.length);
            if (this.history.length> 50) {
                this.historySet.delete(JSON.stringify(this.history[0]));
                this.history.shift();
                console.log('after remove', this.history.length);
            }
       }
    }

    getList() {
        return [...this.history].reverse();
    }

    pop() {
        if (this.history.length<= 0) return null;
        this.history.pop();
        if (this.history.length<= 0) return null;
        return this.history[this.history.length-1];
    }

    pop_to_first() {
        if (this.history.length<= 0) return null;
        this.history.splice(1);
        return this.history[0];
    }

    remove(entry) {
        const index = this.history.indexOf(entry);
        if (index !== -1) {
            this.history.splice(index, 1);
        }
    }

    size() {
        return this.history.length;
    }
}

const instance = new QueryHistory();
Object.freeze(instance);
export default instance;
