import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Bar,
    BarChart, Brush,
    CartesianGrid,
    Cell,
    Line,
    LineChart,
    Pie,
    PieChart, ReferenceArea,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
// import styles from './YourStyles.module.css';
import styles from './SearchViewer.module.css';
import {SimpleSubTitle} from "../Headings/Heading";
import MaxModal from "../MaxModal/MaxModal";
import ReactJson from "react-json-view";
import {FaDatabase, FaHistory, FaSearchPlus} from "react-icons/fa";
import {AiOutlineClose} from "react-icons/ai";
import {useGlobalState} from "../../context/GlobalState";
import {LuArrowLeftToLine, LuArrowRightToLine} from "react-icons/lu";

// Define colors for pie chart
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82ca9d'];

const AggregationChart = ({ new_aggregate, handleClick, handleSelectDataRange }) => {
    // Helper function to render a line chart for date-based aggregations
    const [xLeft, setXLeft] = useState("");
    const [xRight, setXRight] = useState("");
    const { isMobile } = useGlobalState();

    // Refs to store the latest values of xLeft and xRight
    // const xLeftRef = useRef("");
    // const xRightRef = useRef("");


    useEffect(() => {
        setXLeft("");
        setXRight("");
    }, [new_aggregate]);

    // Handler for click events on the chart
    const handleChartClick = (event) => {
        if (event && event.activePayload) {
            const clickedData = event.activePayload[0].payload; // Get the data point where the user clicked
            // console.log("User clicked on:", clickedData);

            if (handleClick) handleClick(clickedData?.name || clickedData?.date);
        }
    };

    const handleBrushChange = (range) => {
        // setSelectedRange(range);
        console.log("Selected Range:", range);
    };

    const zoom = () => {
        // console.log('Zoom:', xLeftRef.current, xRightRef.current, xLeft, xRight);
        console.log('Zoom:', isMobile, xLeft, xRight);
        if (xLeft && xRight)
        {
            if (handleSelectDataRange) handleSelectDataRange(xLeft, xRight);
            setXLeft("");
            setXRight("");
        }
        // Perform zoom logic here using xLeftRef.current and xRightRef.current
    };

    const zoomToMaxLeft = () => {
        setXRight( xLeft);
        setXLeft()
    }
    const zoomToMaxRight = () => {

    }

    const renderLineChart = (aggregate, title) => {
        const chartData = aggregate.buckets?.map(bucket => ({
            date: bucket.key_as_string, // The date from the aggregation
            count: bucket.doc_count      // The document count for that date
        }));
        const xMin = aggregate.buckets[0].key_as_string;
        const xMax = aggregate.buckets[aggregate.buckets.length-1].key_as_string;

        return (
            <div className={styles["histo-container"]}>
                <div className={styles["title-row"]}>
                    <SimpleSubTitle>{title}</SimpleSubTitle>
                </div>
                {xLeft && <div className={styles['selection-on-graph']}>
                    <button
                        onClick={() => {
                            setXRight(xLeft);
                            setXLeft(xMin);
                        }}
                        className={styles['drill-down-icon']}
                        // style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    >
                        <LuArrowLeftToLine  size={16}/>
                    </button>
                    <div>{xLeft} - {xRight}</div>
                    <button
                        onClick={() => {
                            setXRight(xMax);
                        }}
                        className={styles['drill-down-icon']}
                        // style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    >
                        <LuArrowRightToLine  size={16}/>
                    </button>
                    {xRight && (
                        <button
                            onClick={() => zoom()}
                            className={styles['drill-down-icon']}
                            // style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                            <FaSearchPlus size={16}/>
                        </button>
                    )}
                    <button
                        onClick={() => {
                            setXLeft("");
                            setXRight("");
                        }}
                        className={styles['drill-down-icon']}
                        // style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    >
                        <AiOutlineClose size={16}/>
                    </button>
                </div>}
                <ResponsiveContainer width="100%" height={250}>
                    <LineChart
                        onMouseDown={(e) => {
                            if (e.activeLabel)
                                console.log('Mouse down:', e.activeLabel);
                            if (!xLeft)
                                setXLeft(e.activeLabel);
                            // xLeftRef.current = e.activeLabel;
                        }}
                        onMouseMove={(e) => {
                            if (xLeft && e.activeLabel) {
                                console.log('Mouse move:', e.activeLabel);
                                // console.log('Mouse move:', e.activeLabel);
                                setXRight(e.activeLabel);
                                // xRightRef.current = e.activeLabel; // Update the ref
                            }
                        }}
                        onMouseUp={() => {
                            console.log('Mouse up');
                            if (!isMobile && xLeft && xRight) zoom();
                        }}
                        data={chartData}
                        onClick={(e) => {
                            console.log('on click:', e.activeLabel);
                            if (e && e.activeLabel) {
                                if (!xLeft) {
                                    // Set the first point (xLeft)
                                    console.log('First click (xLeft):', e.activeLabel);
                                    setXLeft(e.activeLabel);
                                } else {
                                    // Set the second point (xRight)
                                    console.log('Second click (xRight):', e.activeLabel);
                                    setXRight(e.activeLabel);

                                    // Trigger zoom logic after both points are selected
                                    if (!isMobile) zoom();
                                }
                                // else {
                                //     // Reset if both points are already selected
                                //     console.log('Resetting selection');
                                //     setXLeft("");
                                //     setXRight("");
                                // }
                            }
                        }}


                        // onClick={handleChartClick}
                    >
                        <CartesianGrid strokeDasharray="3 3" stroke="#444"/>
                        <XAxis dataKey="date" stroke="#ddd"/>
                        <YAxis stroke="#ddd" yAxisId="1"/>
                        <Tooltip/>
                        <Line type="monotone" dataKey="count" stroke="#82ca9d" strokeWidth={2} yAxisId="1"/>
                        {xLeft && xRight && (<ReferenceArea
                            yAxisId="1"
                            x1={xLeft || "2024-11-01"}
                            x2={xRight || "2024-12-10"}
                            strokeOpacity={0.3}
                            fill="#8884d8"
                            fillOpacity={0.2}
                        />)}
                        {/*<Brush*/}
                        {/*    dataKey="name"*/}
                        {/*    height={30}*/}
                        {/*    stroke="#8884d8"*/}
                        {/*    onChange={handleBrushChange}*/}
                        {/*/>*/}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    };

    // Helper function to render a bar chart for categorical aggregations
    const renderBarChart = (aggregate, title) => {
        const chartData = aggregate.buckets?.map(bucket => ({
            name: bucket.key, // The category name
            count: bucket.doc_count // The document count for that category
        }));
        const height = aggregate?.buckets.length * 24;

        return (
            <div className={styles["histo-container-bar"]}>
                <div className={styles["title-row"]}>
                    <h2>{title}</h2>
                </div>
                <ResponsiveContainer width="100%" height={Math.max(200, height)}>
                    <BarChart data={chartData} layout={"vertical"} onClick={handleChartClick}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#444"/>
                        <XAxis type={"number"} dataKey={"count"} stroke="#ddd"/>
                        <YAxis type={"category"}
                               dataKey={"name"}
                               stroke="#ddd"
                               width={120}
                               tick={{ fontSize: 12 }} // Adjust font size if needed
                               tickFormatter={(label) => label.length > 50 ? `${label.slice(0, 50)}...` : label} // Optional: Truncate long labels
                        />
                        {/*<XAxis dataKey="name" stroke="#ddd"/>*/}
                        {/*<YAxis stroke="#ddd"/>*/}
                        <Tooltip/>
                        <Bar dataKey="count" fill="#82ca9d"/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    };

    // Helper function to render a pie chart for categorical aggregations
    const renderPieChart = (aggregate, title) => {
        const chartData = aggregate.buckets?.map(bucket => ({
            name: bucket.key, // The category name
            count: bucket.doc_count // The document count for that category
        }));

        return (
            <div className={styles["histo-container"]}>
                <div className={styles["title-row"]}>
                    <h2>{title}</h2>
                </div>

                <ResponsiveContainer width="100%" height={250}>
                    <PieChart onClick={handleChartClick}>
                        <Pie
                            data={chartData}
                            dataKey="count"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            label
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                        </Pie>
                        <Tooltip/>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    };



    // Memoized rendering of charts
    // Only recalculate when showHisto or new_aggregate changes
    return useMemo(() => {
        if (!new_aggregate) return null;

        return (
            <div>
                {/* Render the date-based aggregation (document_date_agg) as a line chart */}
                {new_aggregate.document_date_agg && renderLineChart(new_aggregate.document_date_agg, "Publish date")}
                {new_aggregate.index_date_agg && renderLineChart(new_aggregate.index_date_agg, "Index date")}

                {/* Render categorical aggregations as bar or pie charts */}
                {new_aggregate.source_agg && renderBarChart(new_aggregate.source_agg, "Source Distribution", true)}
                {new_aggregate.author_handle_agg && renderBarChart(new_aggregate.author_handle_agg, "Author Handle Distribution", true)}
                {new_aggregate.locations_agg && renderBarChart(new_aggregate.locations_agg, "Location Distribution", true)}
                {new_aggregate.subjects_agg && renderBarChart(new_aggregate.subjects_agg, "Subjects Distribution", true)}
                {new_aggregate.entities_agg && renderBarChart(new_aggregate.entities_agg, "Entities Distribution", true)}
                {new_aggregate.authors_agg && renderBarChart(new_aggregate.authors_agg, "Authors Distribution", true)}
                {new_aggregate.main_topics_agg && renderBarChart(new_aggregate.main_topics_agg, "Main Topics Distribution", true)}
                {new_aggregate.sentiment_agg && renderBarChart(new_aggregate.sentiment_agg, "Sentiment Distribution")}
            </div>
        );
    }, [new_aggregate, xLeft, xRight]);
};

export default AggregationChart;
