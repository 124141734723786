import React, { createContext, useReducer, useContext } from 'react';

// Define action types
const SET_USER = 'SET_USER';
const LOGOUT_USER = 'LOGOUT_USER';
const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
const SET_SEARCH_PARAM = 'SET_SEARCH_PARAM';
const SET_LOADING = 'SET_LOADING';
const SET_INFO_MESSAGE = 'SET_INFO_MESSAGE';
const SET_PARAMETER_TO_MODIFY = 'SET_PARAMETER_TO_MODIFY'
const SET_FILTER_UNDO_COUNT = 'SET_FILTER_UNDO_COUNT'
const SET_IS_MOBILE = 'SET_IS_MOBILE'
const SET_PROFILE = 'SET_PROFILE'

// Initial state
const initialState = {
    user: JSON.parse(localStorage.getItem('user')) || null,
    isMobile: window.matchMedia("(max-width: 768px)").matches,
    searchParam: {
        from:0,
        size:10,
        query: '',
        keywords: '',
        url: '',
        title: '',
        isUnion: true,
        isExactKeyword: false,
        sortBy: "index",
        aggregateBy: "document_date"
    },
    //TODO: Add others..
};

// Create a context
const GlobalStateContext = createContext(initialState);
const GlobalDispatchContext = createContext(null);

// Reducer function to handle state changes
const globalReducer = (state, action) => {
    switch (action.type) {
        case SET_USER:
            return { ...state, user: action.payload };
        case LOGOUT_USER:
            return { ...state, user: null };
        case SET_SEARCH_RESULTS:
            return { ...state, searchResults: action.payload };
        case SET_SEARCH_PARAM:
            return { ...state, searchParam: action.payload };
        case SET_LOADING:
            return { ...state, loading: action.payload };
        case SET_INFO_MESSAGE:
            return { ...state, infoMessage: action.payload };
        case SET_PARAMETER_TO_MODIFY:
            return { ...state, parametersToModify: action.payload };
        case SET_FILTER_UNDO_COUNT:
            return { ...state, filterUndoCount: action.payload };
        case SET_IS_MOBILE:
            return {...state, isMobile: action.isMobile};
        case SET_PROFILE:
            return {...state, profile: action.profile};
        //TODO: Add others..
        default:
            return state;
    }
};

// GlobalStateProvider component to wrap the app
export const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalReducer, initialState);

    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    );
};

// Custom hooks to use the global state and dispatch
export const useGlobalState = () => useContext(GlobalStateContext);
export const useGlobalDispatch = () => useContext(GlobalDispatchContext);

// Action creators
export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
});
export const setSearchResults = (results) => ({
    type: SET_SEARCH_RESULTS,
    payload: results,
});
export const setFilterUndoCount = (filterUndoCount) => ({
    type: SET_FILTER_UNDO_COUNT,
    payload: filterUndoCount,
});

export const setSearchParam = (params) => ({
    type: SET_SEARCH_PARAM,
    payload: params,
});

export const setLoading = (loading) => ({
    type: SET_LOADING,
    payload: loading
});

export const setInfoMessage = (infoMessage) => ({
    type: SET_INFO_MESSAGE,
    payload: infoMessage
});

export const modifyParameter = (args) => ({
    type: SET_PARAMETER_TO_MODIFY,
    payload: args
});

export const setIsMobile = (isMobile) => ({
    type: SET_IS_MOBILE,
    isMobile
})
export const setProfile = (profile) => ({
    type: SET_PROFILE,
    profile
})


//TODO: Add others set**()..


export const logoutUser = () => ({
    type: LOGOUT_USER,
});
